import React, { useEffect } from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Formsy from 'formsy-react';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import useForm from "utils/useForm";
// @material-ui/icons components
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import CardActions from "@material-ui/core/CardActions";
import Pagination from "@material-ui/lab/Pagination";
import { TextField } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
// core components
import HeaderClasses from "assets/theme/components/header.js";
import { useDispatch, useSelector } from "react-redux";
import componentStyles from "assets/theme/views/admin/dashboard.js";
import tableStyles from "assets/theme/views/admin/tables.js";
import * as Action from 'store/actions/provisioning';
import { Helmet } from 'react-helmet'
  
  const useStyles = makeStyles(componentStyles);
  const useTableStyles = makeStyles(tableStyles);
  
  const useHeaderStyles = makeStyles(HeaderClasses);

  let reRenderCounter = true;

  function ProvisioningTemplate() {

    const classes = useStyles();
    const headerClasses = useHeaderStyles();
    const tableClasses = useTableStyles();
    const theme = useTheme();
    const [activeNav, setActiveNav] = React.useState(1);
    const [disabledInput, setDisabledInput] = React.useState(false);
    const provisioning = useSelector(({provisioning}) => provisioning.provisioning);
    const {form, handleChange, setForm} = useForm(provisioning? provisioning : {});
    const setup = useSelector(({setup}) => setup.setup);

    const dispatch = useDispatch();

    function submitProvisioning() {
  
      dispatch(Action.createProvisioning(form))
  
    }

    function handleSubmit(e) {
      if(document.getElementById('manager').getElementsByClassName('MuiButton-label')[0].innerHTML == 'CREATE') {
        setDisabledInput(true);
        dispatch(Action.createProvisioning(form))
        document.getElementById('manager').getElementsByClassName('MuiButton-label')[0].innerHTML = 'EDIT'
      }  
      else if(document.getElementById('manager').getElementsByClassName('MuiButton-label')[0].innerHTML == 'EDIT') {
          setDisabledInput(false);
          document.getElementById('manager').getElementsByClassName('MuiButton-label')[0].innerHTML = 'DONE'
        } else {
          dispatch(Action.editProvisioning(form))
          document.getElementById('manager').getElementsByClassName('MuiButton-label')[0].innerHTML = 'EDIT'
          setDisabledInput(true);
        }
    }

    function handleProvisioningButton(e) {

     

    }

    useEffect(() => {
      dispatch(Action.getProvisioning());
      if(provisioning) {
        setDisabledInput(true);
      }
      
  }, [dispatch]);



  if(provisioning) {
    if(reRenderCounter) {
        setForm(provisioning);
        setDisabledInput(true);
        reRenderCounter = false;
    } 
  }


    return (
        <>
        <Helmet>
          <title>{`Provisioning Template | ${setup.organizationName}`}</title>
        </Helmet>
        <Box minHeight="1rem" >
          
        </Box>
      
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="6rem"
        marginBottom="3rem"
        classes={{ root: classes.containerRoot }}
      >

        <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        style={{
                            color: 'primary'
                        }}
                        marginBottom="0!important"
                      >
                        <Typography color="primary" style={{fontWeight: 'bold'}}>Provisioning Template</Typography>
                      </Box>
                    </Grid>
                    
                  </Grid>
                }
                style={{
                  background:'#F7FAFC'
                }}
              ></CardHeader>
              <CardContent>
              <Formsy onValidSubmit={handleSubmit}>
                <Box
                  component={Typography}
                  variant="h2"
                  color={'#57737A' + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: classes.typographyRootH6 }}
                >
                  APP DETAILS
                </Box>
                <div className={classes.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Application Name</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            disabled={disabledInput}
                            name="applicationName"
                            value={form.applicationName}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                            type="text"
                            placeholder="Application Name"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Sub Application Name</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            disabled={disabledInput}
                            name="subApplicationName"
                            value={form.subApplicationName}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                            type="text"
                            placeholder="Sub Application Name"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Classification</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            disabled={disabledInput}
                            name="classification"
                            value={form.classification}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                            type="text"
                            placeholder="Classification"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>ARN No</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            disabled={disabledInput}
                            name="arnNo"
                            value={form.arnNo}
                            onChange={handleChange}
                            required
                            autoComplete="off"
                            type="text"
                            placeholder="ARN No"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Category</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            disabled={disabledInput}
                            name="category"
                            value={form.category}
                            required
                            onChange={handleChange}
                            autoComplete="off"
                            type="text"
                            placeholder="Category"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Sub Category</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            disabled={disabledInput}
                            name="subCategory"
                            required
                            value={form.subCategory}
                            onChange={handleChange}
                            autoComplete="off"
                            type="text"
                            placeholder="Sub Category"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Status</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            disabled={disabledInput}
                            required
                            autoComplete="off"
                            name="status"
                            value={form.status}
                            onChange={handleChange}
                            type="text"
                            placeholder="Status"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Company</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            name="company"
                            value={form.company}
                            onChange={handleChange}
                            component={FilledInput}
                            required
                            disabled={disabledInput}
                            autoComplete="off"
                            type="text"
                            placeholder="Company"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Tier</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            disabled={disabledInput}
                            name="tier"
                            value={form.tier}
                            required
                            onChange={handleChange}
                            autoComplete="off"
                            type="text"
                            placeholder="Tier"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    
                  </Grid>
                  
                </div>
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: classes.typographyRootH6 }}
                >
                  Deployment
                </Box>
                <div className={classes.plLg4}>
                  
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Datacenter</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            disabled={disabledInput}
                            name="dataCenter"
                            value={form.dataCenter}
                            required
                            onChange={handleChange}
                            autoComplete="off"
                            type="text"
                            placeholder="Datacenter"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>NDR</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            name="ndr"
                            value={form.ndr}
                            onChange={handleChange}
                            component={FilledInput}
                            required
                            disabled={disabledInput}
                            autoComplete="off"
                            type="text"
                            placeholder="NDR"
                          />
                        </FormControl>
                      </FormGroup>
                      
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Recovery</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            disabled={disabledInput}
                            name="recovery"
                            required
                            value={form.recovery}
                            onChange={handleChange}
                            autoComplete="off"
                            type="text"
                            placeholder="Recovery"
                          />
                        </FormControl>
                      </FormGroup>
                      
                    </Grid>
                    
                  </Grid>
                </div>
                
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: classes.typographyRootH6 }}
                >
                  Owners
                </Box>
                <div className={classes.plLg4}>
                  
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>First Owner</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            disabled={disabledInput}
                            name="firstOwner"
                            required
                            value={form.firstOwner}
                            onChange={handleChange}
                            autoComplete="off"
                            type="text"
                            placeholder="First Owner"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Second Owner</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            disabled={disabledInput}
                            name="secondOwner"
                            required
                            value={form.secondOwner}
                            onChange={handleChange}
                            autoComplete="off"
                            type="text"
                            placeholder="Second Owner"
                          />
                        </FormControl>
                      </FormGroup>
                      
                    </Grid>
                    
                  </Grid>
                </div>

                <Box
                textAlign="center"
                marginTop="1.5rem"
                marginBottom="1.5rem"
                paddingBottom={"1.5rem"}
              >
               
                  <Button
                  type="submit"
                  id="manager"
                  
                    style={{
                      background: "#e30c57",
                      border: "#e30c57",
                      paddingLeft: "90px",
                      paddingRight: "90px",
                      borderRadius: "5px",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    }}
                    variant="contained"
                  >
                    {provisioning ? 'EDIT' : 'CREATE'}
                    
                  </Button>
                
              </Box>

              </Formsy>
                
              </CardContent>
            </Card>

            

      </Container>

   

      </>

    )
}

export default ProvisioningTemplate;