import React, {useEffect} from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { TextField } from "@mui/material";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CloseIcon from '@mui/icons-material/Close';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from "react-router-dom";
// @material-ui/icons components
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import CardActions from "@material-ui/core/CardActions";
import MoreVert from "@material-ui/icons/MoreVert";
import Pagination from "@material-ui/lab/Pagination";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector, useDispatch } from 'react-redux';
// core components
import HeaderClasses from "assets/theme/components/header.js";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { InputAdornment } from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import componentStyles from "assets/theme/views/admin/dashboard.js";
import tableStyles from "assets/theme/views/admin/tables.js";
import { Divider, FilledInput } from "@material-ui/core";
import * as Action from 'store/actions/request';
import { Helmet } from 'react-helmet'
  
  const useStyles = makeStyles(componentStyles);
  const useTableStyles = makeStyles(tableStyles);
  
  const useHeaderStyles = makeStyles(HeaderClasses);



  function ApproverResourceRequest(props) {

    const classes = useStyles();
    const headerClasses = useHeaderStyles();
    const tableClasses = useTableStyles();
    const theme = useTheme();
    const setup = useSelector(({setup}) => setup.setup);
    const dispatch = useDispatch();

    const [anchorEl1, setAnchorEl1] = React.useState(null);

    const handlePagination = (event) => {
      dispatch(Action.getAllRequests(event.target.textContent));
    };

    const handleClick = (event) => {
      switch (event.currentTarget.getAttribute("aria-controls")) {
        case "simple-menu-1":
          setAnchorEl1(event.currentTarget);
          break;
        default:
      }
    };

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleClose = () => {
      setAnchorEl1(null);
    
    };

    const handleSearch = (event) => {
      dispatch(Action.searchRequests(event.target.value));
      // dispatch(Actions.searchUsers(event.target.value));
    }

    const [activeNav, setActiveNav] = React.useState(1);
    const user = useSelector(({auth}) => auth.user.data);
    const [open, setOpen] = React.useState(false);
    const requests = useSelector(({request}) => request.requests);
    const pageNumbers = useSelector(({request}) => request.pageNumbers);


    const handleDelete = () => {
      setAnchorEl1(null);
      setOpen(true);
    }
  
    const handleCloseDialog = () => {
      setOpen(false);
    };

    useEffect(() => {
      dispatch(Action.getAllRequests(1));  
      
  }, [dispatch]);

  if(!requests) {
    return null;
  }

    return (
        <>
        <Helmet>
          <title>{`Resource Request | ${setup.organizationName}`}</title>
        </Helmet>
        <Box minHeight="1rem" >
          
        </Box>
      
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="6rem"
        classes={{ root: classes.containerRoot }}
      >

        <Card
              classes={{
                root: classes.cardRoot,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        <Typography color="primary" style={{fontWeight: 'bold'}}>Resource List</Typography>
                      </Box>
                    </Grid>

                    <Paper
                      component="form"
                      style={{
                        boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.02), 0px 1px 3px rgba(50, 50, 93, 0.15)"
                      }}
                      sx={{  display: 'flex', alignItems: 'center', width: 500 }}
                    >
                      
                      
                      <FilledInput
                      
                        startAdornment={
                          <InputAdornment  position="start">
                          <SearchIcon />
                          </InputAdornment>
                        }

                      style={{
                        boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.02)"
                      }}
                      onChange={handleSearch}
                        sx={{ ml: 1, flex: 1,  }}
                        placeholder="Search by RFC No"
                        inputProps={{ 'aria-label': 'Search by RFC No' }}
                      />
                      
                    </Paper>

                    <Grid item xs="auto">
                     
                    </Grid>
                  </Grid>
                }

                style={{
                  height: '70px'
                }}
               
              ></CardHeader>
              <TableContainer>
                <Box
                  component={Table}
                  alignItems="center"
                  marginBottom="0!important"
                >
                  <TableHead>
                    <TableRow>
                    <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        RFC No
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Environment
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Time
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Request Status
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        
                      </TableCell>

                      <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  ></TableCell>
                     
                    </TableRow>
                  </TableHead>
                  <TableBody>

                  {requests.map((request, i) => 

                    <TableRow>
                    <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        {request.rfcNo}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        {request.environment}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                      {new Date(request.createdAt).toLocaleDateString()} {new Date(request.createdAt).toLocaleTimeString()}
                      </TableCell>
                    
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box paddingTop=".35rem" paddingBottom=".35rem">
                      <Box
                        marginRight="10px"
                        component="i"
                        width=".375rem"
                        height=".375rem"
                        borderRadius="50%"
                        display="inline-block"
                        className={
                          `${tableClasses.verticalAlignMiddle} + " " + ${ request.status == 'pending' || request.status == 'approved'? tableClasses.bgPending: request.status == 'rejected' || request.status == 'failed' ? tableClasses.bgError:  tableClasses.bgSuccess}`
                        }
                      ></Box>
                        {request.status == 'approved' ? 'Executing' : capitalizeFirstLetter(request.status)}
                        </Box>
                      </TableCell>
                      <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    align="right"
                    >
                    
                    
                    </TableCell>
                      <TableCell align="right" classes={{ root: classes.tableCellRoot }}>
                      <Link to={`/${user.role}/viewrequest/${request._id}`} style={{color: '#57737A', textDecoration: 'none'}}>
                        <VisibilityIcon style={{color:'#C5C5C5'}} />

                      </Link>
                      </TableCell>
                      
                      
                    </TableRow>


                  )}

                    
                   
                    
                    
                    
                  </TableBody>
                </Box>
              </TableContainer>

              <Box
            classes={{ root: classes.cardActionsRoot }}
            component={CardActions}
            justifyContent="flex-end"
          >
            <Pagination hideNextButton hidePrevButton count={3} color="primary" variant="outlined" onClick={handlePagination} count={pageNumbers} />
          </Box>
            </Card>

            

            <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        style={{
          boxShadow: "none",
          
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle  style={{background: '#e30c57', display: 'flex',
    justifyContent: 'space-between'}} color="white" id="alert-dialog-title">
            Delete User <CloseIcon />
        </DialogTitle>
        <DialogContent>
          <DialogContentText paddingTop='30px' id="alert-dialog-description">
            Are you sure you want to delete this user? This process can not be reversed
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{paddingLeft:"70px", paddingRight:"20px", paddingBottom: "25px"}}>
          <Button fullWidth variant="outlined"
                          color="primary" onClick={handleCloseDialog}>Cancel</Button>
          <Button fullWidth variant="contained"
                          color="primary" onClick={handleCloseDialog}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </Container>



        </>

    );

  }

  export default ApproverResourceRequest;