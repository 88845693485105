import axios from 'axios';

import * as Action from '../helper/index';
export const GET_PROVISIONING = 'GET_PROVISIONING';
export const NO_PROVISIONING = 'NO_PROVISIONING';

export function getProvisioning()
{

    return (dispatch) =>
        axios
            .get('/api/v1/provisioning/getprovisioning')
            .then(res => {  

                if(!res.data.provisioning) {

                    return dispatch({
                        type: NO_PROVISIONING
                    });

                }

                return dispatch({
                    type: GET_PROVISIONING,
                    payload: res.data.provisioning
                });

            }).catch(error => {
         
                console.log(error)

        })
}