import React, {useEffect} from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { FormControlLabel } from "@mui/material";
import useForm from "utils/useForm";
import { Checkbox } from "@mui/material";
import { TextField } from "@mui/material";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CloseIcon from '@mui/icons-material/Close';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from "react-router-dom";
// @material-ui/icons components
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import CardActions from "@material-ui/core/CardActions";
import MoreVert from "@material-ui/icons/MoreVert";
import Pagination from "@material-ui/lab/Pagination";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector, useDispatch } from 'react-redux';
// core components
import HeaderClasses from "assets/theme/components/header.js";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { InputAdornment } from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import componentStyles from "assets/theme/views/admin/dashboard.js";
import tableStyles from "assets/theme/views/admin/tables.js";
import { Divider, FilledInput } from "@material-ui/core";
import * as Action from 'store/actions/request';
import { Helmet } from 'react-helmet'
  
  const useStyles = makeStyles(componentStyles);
  const useTableStyles = makeStyles(tableStyles);
  
  const useHeaderStyles = makeStyles(HeaderClasses);



  function BulkApprover(props) {

    const classes = useStyles();
    const headerClasses = useHeaderStyles();
    const tableClasses = useTableStyles();
    const theme = useTheme();
    const setup = useSelector(({setup}) => setup.setup);
    const dispatch = useDispatch();
    const {form, handleChange, setForm, setInForm} = useForm({});

    const [anchorEl1, setAnchorEl1] = React.useState(null);

    const handlePagination = (event) => {
      dispatch(Action.getAllRequests(event.target.textContent));
    };

    const handleClick = (event) => {
      switch (event.currentTarget.getAttribute("aria-controls")) {
        case "simple-menu-1":
          setAnchorEl1(event.currentTarget);
          break;
        default:
      }
    };

    const [reason, setReason] = React.useState(null);

      function handleApprove() {

        var requestsList = [];
        function adder(value) {
          requestsList.push(value)
        }

        for (var key in form) {

          if(form[key] == true) {
            
            adder(key)
            
          }
          
        }

        dispatch(Action.bulkApproveRequest(requestsList, props))

      }

      function handleReasonChange(event) {
        setReason(event.target.value)
      }

      function handleDecline() {
     
        var requestsList = [];
        function adder(value) {
          requestsList.push(value)
        }

        for (var key in form) {

          if(form[key] == true) {
            
            adder(key)
            
          }
          
        }

        dispatch(Action.bulkRejectRequest(requestsList, reason, props))
        
      }



      function handleCheckBox(event) {


        var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')


    

        // if(event.target.checked) {
        //     event.target.checked = false;
        // } else if(!event.target.checked) {
        //     event.target.checked = true;
        // }

      }

      function handleSelectAll(event) {

        if(event.target.checked) {

          requests.forEach(function (request) {
            setInForm(request._id.toString(), true);
          })

          const checkboxes = document.getElementsByClassName('request');     
          for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = true
          }
            
          } else {

            requests.forEach(function (request) {
              setInForm(request._id.toString(), false);
            })

            const checkboxes = document.getElementsByClassName('request');     
          for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false
          }

          }

      }

   

    const handleClose = () => {
      setAnchorEl1(null);
    
    };

    const getCheckboxValue = (id) => {

      return form[id]
    }

    const handleSearch = (event) => {
      dispatch(Action.searchRequests(event.target.value));
      // dispatch(Actions.searchUsers(event.target.value));
    }

    const [activeNav, setActiveNav] = React.useState(1);
    const user = useSelector(({auth}) => auth.user.data);
    const [open, setOpen] = React.useState(false);
    const requests = useSelector(({request}) => request.requests);
    const pageNumbers = useSelector(({request}) => request.pageNumbers);




    useEffect(() => {
      dispatch({
        type   : Action.GET_USER_REQUESTS,
        payload: []
        });
      dispatch(Action.getPendingRequests());  
      
  }, [dispatch]);



    // useEffect(() => {
    //   requests.forEach(function (request) {
    //     setInForm(request._id.toString(), true);
    //   })
    // }, [requests]);

  if(!requests) {
    return null;
  }

  if(!setup) {
    return null;
  }
  

    return (
        <>
       
        <Helmet>
          <title>{`Bulk Approver | ${setup.organizationName}`}</title>
        </Helmet>
        <Box minHeight="1rem" >
          
        </Box>
      
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="6rem"
        classes={{ root: classes.containerRoot }}
      >

        <Card
              classes={{
                root: classes.cardRoot,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        <Typography color="primary" style={{fontWeight: 'bold'}}>Resource List</Typography>
                      </Box>
                    </Grid>

                   

                  
                  </Grid>
                }

                style={{
                  height: '70px'
                }}
               
              ></CardHeader>
              <TableContainer>
                <Box
                  component={Table}
                  alignItems="center"
                  marginBottom="0!important"
                >
                  <TableHead>
                    <TableRow>
                    <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        RFC No
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Environment
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Time
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      > select
                        <input type="checkbox" onClick={handleSelectAll}  value='selectAll' color="primary" />
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        
                      </TableCell>

                      <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  ></TableCell>
                     
                    </TableRow>
                  </TableHead>
                  <TableBody>

                  {requests.map((request, i) =>  

                    <TableRow>
                    <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        {request.rfcNo}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        {request.environment}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                      {new Date(request.createdAt).toLocaleDateString()} {new Date(request.createdAt).toLocaleTimeString()}
                      </TableCell>
                    
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                      <input type='checkbox' onChange={handleChange} class='request'  name={request._id} value={request._id}   color="primary" />
                      </TableCell>
                      <TableCell align="right" classes={{ root: classes.tableCellRoot }}>
                      <Link to={`/${user.role}/viewrequest/${request._id}`} style={{color: '#57737A', textDecoration: 'none'}}>
                        <VisibilityIcon style={{color:'#C5C5C5'}} />

                      </Link>
                      </TableCell>
                      
                      
                    </TableRow>

                      )}
    
                  </TableBody>
                </Box>
              </TableContainer>

              

             
            </Card>

            {requests === undefined || requests.length == 0 ? '' :


                <Container>

                <Grid container style={{
                    marginTop:"40px"
                }}>
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Give a reason for declining this form</FormLabel>
                            <FormControl
                              variant="filled"
                              component={Box}
                              width="100%"
                              marginBottom="1rem!important"
                            >
                              <Box
                                paddingLeft="0.75rem"
                                paddingRight="0.75rem"
                                component={FilledInput}
                                autoComplete="off"
                                name="reason"
                                value={reason}
                                onChange={handleReasonChange}
                                multiline
                                placeholder="Enter your reason here"
                                rows="4"
                              />
                            </FormControl>
                          </FormGroup>
                        </Grid>
                      </Grid>

                      <Box sx={{
                        
                        display: 'flex',
                        justifyContent: 'flex-end',
                      
                        borderRadius: 1,
                        }} style={{paddingTop: '5px', marginTop: '20px', marginBottom: '20px'}}>
                      <Button onClick={handleDecline} style={{paddingLeft: '60px' , marginRight: '30px',
                          paddingRight: '60px', borderRadius: '5px',
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)"}}  variant="outlined"
                              color="primary" >Decline</Button>
                        <Button onClick={handleApprove} style={{background: '#e30c57', border: '#e30c57', paddingLeft: '60px' ,
                          paddingRight: '60px', borderRadius: '5px',
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)"}}   variant="contained"
                                        color="primary" >
                            Approve
                        </Button>

                    </Box>

                    </Container>

          }
            
      

    </Container>



        </>

    );

  }

  export default BulkApprover;