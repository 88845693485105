import * as Actions from '../../actions/request';

const initialState = {
    requests: null,
    request: null,
    requestor: null,
    pageNumbers: null,
    total: null,
    accepted: null,
    pending: null
};

const request = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_USER_REQUESTS:
        {
            return {
                ...initialState,
                requests  : action.payload,
                pageNumbers: action.pageNumbers,
                total: action.total,
                accepted: action.accepted,
                pending: action.pending
            };
        }
        case Actions.GET_REQUEST:
        {
            return {
                ...initialState,
                request  : action.payload,
                requestor: action.requestor
            };
        }
        default:
        {
            return state
        }
    }
};

export default request;