import axios from "axios";
import * as snackAction from 'store/actions/helper';


export function bulkApproveRequest(requests, props)
{

    return (dispatch) =>
        axios
            .post(`api/v1/request/bulkapproverequests`, {requests})
            .then(res => {  
                dispatch(snackAction.showMessage('Requests approved successfully', "success"));
                
                props.history.push('/approver/resourcerequests')

            }).catch(error => {
                dispatch(snackAction.showMessage(error.message, "error"));
                console.log(error)

        })
}