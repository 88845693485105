import axios from "axios";
import * as snackAction from 'store/actions/helper';
export const APPROVE_REQUEST = 'APPROVE_REQUEST';


export function approveRequest(requestId, props)
{

    return (dispatch) =>
        axios
            .get(`api/v1/request/approverequest/${requestId}`)
            .then(res => {  
                dispatch(snackAction.showMessage('Request approved successfully', "success"));
                dispatch({
                    type   : APPROVE_REQUEST
                });
                props.history.push('/approver/resourcerequests')

            }).catch(error => {
                dispatch(snackAction.showMessage(error.message, "error"));
                console.log(error)

        })
}