import * as Actions from '../../actions/auth';

const initialState = {
    success: false,
    error  : null,
    user: null
};

const login = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.LOGIN_SUCCESS:
        {
            return {
                ...initialState,
                success: true
            };
        }
        case Actions.LOGIN_ERROR:
        {
            return {
                success: false,
                error  : action.payload
            };
        }
        case Actions.SET_USER_DATA:
        {
            return {
                ...initialState,
                user  : action.payload
            };
        }
        default:
        {
            return state
        }
    }
};

export default login;