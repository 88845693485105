import axios from "axios";
import * as snackAction from 'store/actions/helper';
import * as Action from './createProvisioning.actions';


export function editProvisioning(model)
{

    return (dispatch) =>
        axios
            .post(`api/v1/provisioning/createprovisioning`, model)
            .then(res => {  
                dispatch(snackAction.showMessage('Provisioning updated successfully', "success"));
                dispatch({
                    type   : Action.CREATE_PROVISIONING
                });

            }).catch(error => {

                dispatch(snackAction.showMessage(error.response.data.error, "error"));

        })
}