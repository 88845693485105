import React, { useEffect } from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// @material-ui/core components
import * as snackAction from "store/actions/helper";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import { Badge } from "@mui/material";
import Box from "@material-ui/core/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import CardActions from "@material-ui/core/CardActions";
import Pagination from "@material-ui/lab/Pagination";
import { Select, TextField } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { useSelector, useDispatch } from "react-redux";
// core components
import HeaderClasses from "assets/theme/components/header.js";
import { MenuItem } from "@material-ui/core";
import componentStyles from "assets/theme/views/admin/dashboard.js";
import tableStyles from "assets/theme/views/admin/tables.js";
import { styled } from "@mui/material/styles";
import boxShadows from "assets/theme/box-shadow.js";
import { borderRight } from "@mui/system";
import useForm from "utils/useForm";
import * as Action from "store/actions/provisioning";
import * as Actions from "store/actions/request";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(componentStyles);
const useTableStyles = makeStyles(tableStyles);

const useHeaderStyles = makeStyles(HeaderClasses);

let reRenderCounter = true;

function handleSchemaToApi(form) {
  const schema = {
    status: form.status,
    environment: form.deploymentEnvironment,
    deploymentScope: {
      serverProvisioning: form.serverProvisioning,
      productProvisioning: form.productProvisioning,
    },
    server: {
      appServer: form.appServer,
      webServer: form.webServer,
      databaseServer: form.dbServer,
    },

    serverProvisioning: {
      appServer: {
        serverType: form.serverTypeA,
        numberOfInstances: form.numberOfInstancesA,
        osType: form.osTypeA,
        osVersion: form.osVersionA,
        numberOfCpu: form.numberOfCpuA,
        memoryInGb: form.memoryInGbA,
        mountPointInGb: form.mountPointInGbA,
        serversRequiredInUatDmz: form.serversRequiredInUatDmzA,
        nonDmzServersRequired: form.nonDmzServersRequiredA,
      },
      webServer: {
        serverType: form.serverTypeW,
        numberOfInstances: form.numberOfInstancesW,
        osType: form.osTypeW,
        osVersion: form.osVersionW,
        numberOfCpu: form.numberOfCpuW,
        memoryInGb: form.memoryInGbW,
        mountPointInGb: form.mountPointInGbW,
        serversRequiredInUatDmz: form.serversRequiredInUatDmzW,
        nonDmzServersRequired: form.nonDmzServersRequiredW,
      },
      databaseServer: {},
    },
    productInstallation: {
      appServer: {
        productOem: form.productOemA,
        productName: form.productNameA,
        productVersion: form.productVersionA,
        mountPoint: form.mountPointA,
      },
      webServer: {
        productOem: form.productOemW,
        productName: form.productNameW,
        productVersion: form.productVersionW,
        mountPoint: form.mountPointW,
      },
      databaseServer: {},
    },
  };

  return schema;
}

function handleSchemaFromApi(form) {
  var schema = {
    serverProvisioning: form.deploymentScope.serverProvisioning,
    appServer: form.server.appServer,
    dbServer: form.server.databaseServer,
    productProvisioning: form.deploymentScope.productProvisioning,
    webServer: form.server.webServer,
    deploymentEnvironment: form.environment,
  };

  if (form.deploymentScope.serverProvisioning) {
    if (form.server.appServer) {
      schema.serverTypeA = form.serverProvisioning.appServer.serverType;
      schema.numberOfInstancesA =
        form.serverProvisioning.appServer.numberOfInstances;
      schema.osTypeA = form.serverProvisioning.appServer.osType;
      schema.osVersionA = form.serverProvisioning.appServer.osVersion;
      schema.numberOfCpuA = form.serverProvisioning.appServer.numberOfCpu;
      schema.memoryInGbA = form.serverProvisioning.appServer.memoryInGb;
      schema.mountPointInGbA = form.serverProvisioning.appServer.mountPointInGb;
      schema.serversRequiredInUatDmzA =
        form.serverProvisioning.appServer.serversRequiredInUatDmz;
      schema.nonDmzServersRequiredA =
        form.serverProvisioning.appServer.nonDmzServersRequired;
    }
    if (form.server.webServer) {
      schema.serverTypeW = form.serverProvisioning.webServer.serverType;
      schema.numberOfInstancesW =
        form.serverProvisioning.webServer.numberOfInstances;
      schema.osTypeW = form.serverProvisioning.webServer.osType;
      schema.osVersionW = form.serverProvisioning.webServer.osVersion;
      schema.numberOfCpuW = form.serverProvisioning.webServer.numberOfCpu;
      schema.memoryInGbW = form.serverProvisioning.webServer.memoryInGb;
      schema.mountPointInGbW = form.serverProvisioning.webServer.mountPointInGb;
      schema.serversRequiredInUatDmzW =
        form.serverProvisioning.webServer.serversRequiredInUatDmz;
      schema.nonDmzServersRequiredW =
        form.serverProvisioning.webServer.nonDmzServersRequired;
    }
  }

  if (form.deploymentScope.productProvisioning) {
    if (form.server.appServer) {
      schema.productOemA = form.productInstallation.appServer.productOem;
      schema.productNameA = form.productInstallation.appServer.productName;
      schema.productVersionA =
        form.productInstallation.appServer.productVersion;
      schema.mountPointA = form.productInstallation.appServer.mountPoint;
    }

    if (form.server.webServer) {
      schema.productOemW = form.productInstallation.webServer.productOem;
      schema.productNameW = form.productInstallation.webServer.productName;
      schema.productVersionW =
        form.productInstallation.webServer.productVersion;
      schema.mountPointW = form.productInstallation.webServer.mountPoint;
    }
  }

  return schema;
}

function ViewRequest(props) {
  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const tableClasses = useTableStyles();
  const theme = useTheme();
  const [activeNav, setActiveNav] = React.useState(1);
  const [reason, setReason] = React.useState(null);
  const defaultValue = {
    serverProvisioning: false,
    appServer: false,
    dbServer: false,
    productProvisioning: false,
    webServer: false,
    deploymentEnvironment: "UAT",
    serverTypeA: "virtual",
    serverTypeW: "virtual",
    numberOfInstancesA: null,
    numberOfInstancesW: null,
    osTypeA: "",
    osVersionA: "",
    osTypeW: "",
    osVersionW: "",
    numberOfCpuA: 4,
    numberOfCpuW: 4,
    memoryInGbA: 4 * 8,
    memoryInGbW: 4 * 4,
    mountPointInGbA: 100,
    mountPointInGbW: 100,
    serversRequiredInUatDmzA: 0,
    serversRequiredInUatDmzW: 0,
    nonDmzServersRequiredA: 2,
    nonDmzServersRequiredW: 2,
    productOemA: "",
    productOemW: "",
    productNameA: "",
    productNameW: "",
    productVersionA: "",
    productVersionW: "",
    mountPointA: "",
    mountPointW: "",
  };
  const user = useSelector(({ auth }) => auth.user.data);
  const provisioning = useSelector(
    ({ provisioning }) => provisioning.provisioning
  );
  const provisioningExistence = useSelector(
    ({ provisioning }) => provisioning.exist
  );
  const request = useSelector(({ request }) => request.request);
  const requestor = useSelector(({ request }) => request.requestor);
  const setup = useSelector(({ setup }) => setup.setup);
  const [disableInputs, setDisabledInputs] = React.useState(false);
  const { form, handleChange, setForm } = useForm(defaultValue);

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .Mui-disabled": {
      backgroundColor: "#efefef !important",
    },
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      boxShadow: boxShadows.inputBoxShadow,
      position: "relative",
      backgroundColor: theme.palette.background.paper,

      borderRadius: ".375rem",
      fontSize: 15,
      padding: "10px 26px 10px 22px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));
  const dispatch = useDispatch();

  async function handleSubmitRequest() {
    if (!form.serverProvisioning && !form.productProvisioning) {
      dispatch(
        snackAction.showMessage(
          "You need to select at least server provisioning or product provisioning",
          "error"
        )
      );

      return;
    }

    if (!form.appServer && !form.webServer) {
      dispatch(
        snackAction.showMessage(
          "You need to select at least app server or web server ",
          "error"
        )
      );

      return;
    }

    if (form.serverProvisioning && form.appServer) {
      if (form.numberOfInstancesA == null) {
        dispatch(
          snackAction.showMessage(
            "App server Number of instances can not be empty ",
            "error"
          )
        );
        return;
      } else if (form.osTypeA == "") {
        dispatch(
          snackAction.showMessage(
            "App server OS type can not be empty ",
            "error"
          )
        );
        return;
      } else if (form.osVersionA == "") {
        dispatch(
          snackAction.showMessage(
            "App server OS version can not be empty ",
            "error"
          )
        );
        return;
      } else if (form.numberOfCpuA == null) {
        dispatch(
          snackAction.showMessage(
            "App server Number of CPU can not be empty ",
            "error"
          )
        );
        return;
      } else if (
        form.serversRequiredInUatDmzA + form.nonDmzServersRequiredA !==
        form.numberOfInstancesA
      ) {
        dispatch(
          snackAction.showMessage(
            "App server Number of instances should be equal to sum of servers required in UAT DMZ & non DMZ servers required",
            "error"
          )
        );
        return;
      }
    }

    if (form.serverProvisioning && form.webServer) {
      if (form.numberOfInstancesW == null) {
        dispatch(
          snackAction.showMessage(
            "Web server Number of instances can not be empty ",
            "error"
          )
        );
        return;
      } else if (form.osTypeW == "") {
        dispatch(
          snackAction.showMessage(
            "Web server OS type can not be empty ",
            "error"
          )
        );
        return;
      } else if (form.osVersionW == "") {
        dispatch(
          snackAction.showMessage(
            "Web server OS version can not be empty ",
            "error"
          )
        );
        return;
      } else if (form.numberOfCpuW == null) {
        dispatch(
          snackAction.showMessage(
            "Web server Number of CPU can not be empty ",
            "error"
          )
        );
        return;
      } else if (
        form.serversRequiredInUatDmzW + form.nonDmzServersRequiredW !==
        form.numberOfInstancesW
      ) {
        dispatch(
          snackAction.showMessage(
            "Web server Number of instances should be equal to sum of servers required in UAT DMZ & non DMZ servers required ",
            "error"
          )
        );
        return;
      }
    }

    if (form.productProvisioning && form.appServer) {
      if (form.productOemA == "") {
        dispatch(
          snackAction.showMessage(
            "App server product OEM can not be empty ",
            "error"
          )
        );
        return;
      } else if (form.productVersionA == "") {
        dispatch(
          snackAction.showMessage(
            "App server product version can not be empty ",
            "error"
          )
        );
        return;
      }

      form.productNameA =
        form.productOemA == "Oracle"
          ? "Weblogic"
          : form.productOemA == "Microsoft"
          ? "IIS"
          : form.productOemA == "IBM"
          ? "Websphere"
          : "";
    }

    if (form.productProvisioning && form.webServer) {
      if (form.productOemW == "") {
        dispatch(
          snackAction.showMessage(
            "Web server product OEM can not be empty ",
            "error"
          )
        );
        return;
      } else if (form.productVersionW == "") {
        dispatch(
          snackAction.showMessage(
            "Web server product version can not be empty ",
            "error"
          )
        );
        return;
      }

      form.productNameW =
        form.productOemW == "Oracle"
          ? "Weblogic"
          : form.productOemW == "Microsoft"
          ? "IIS"
          : form.productOemW == "IBM"
          ? "Websphere"
          : "";
    }

    var data = await handleSchemaToApi(form);

    dispatch(Actions.createRequest(data, props));
  }

  function handleApprove() {
    dispatch(Actions.approveRequest(request._id, props));
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function handleReasonChange(event) {
    setReason(event.target.value);
  }

  function handleDecline() {
    dispatch(Actions.rejectRequest(request._id, reason, props));
  }

  function appServerOsVersion(type) {
    const APXversions = ["AIX 6", "AIX 6.1", "AIX 7.1"];

    const LINUXversions = ["RHEL 7.8", "RHEL 7.9", "RHEL 8.2", "RHEL 8.3"];

    const SOLARISversions = [
      "Solaris 10",
      "Solaris 11.2",
      "Solaris 11.3",
      "Solaris 11.3 ",
    ];

    const WINDOWSversions = ["Windows 2012", "Windows 2016"];

    if (type == "AIX") {
      return APXversions;
    } else if (type == "Linux") {
      return LINUXversions;
    } else if (type == "Solaris") {
      return SOLARISversions;
    } else if (type == "Windows") {
      return WINDOWSversions;
    } else {
      return [];
    }
  }

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  function productInstallationOsVersion(type) {
    const ORACLEversions = [
      "10.2.0.1.0",
      "10.2.0.3.0",
      "10.2.0.4.0",
      "11.2.0.1.0",
      "11.2.0.2.0",
      "11.2.0.3.0",
      "11.2.0.4.0",
      "12.1.0.1.0",
      "12.1.0.2.0",
    ];
    const IBMversions = [
      "IIB - 9.0.0.2",
      "MQ - 7.5.0.3",
      "Connect Direct - 4.2.0.0",
      "Websphere 7.0.0.27",
      "Worklight 7.0",
      "Worklight 8.0",
      "IHS 7.0",
      "IHS 8.0",
    ];
    const MICROSOFTversions = ["6.0", "7.0", "7.5", "8"];

    const OPENSOURCEversions = ["9x", "10x"];

    if (type == "Oracle") {
      return ORACLEversions;
    } else if (type == "IBM") {
      return IBMversions;
    } else if (type == "Microsoft") {
      return MICROSOFTversions;
    } else if (type == "Opensource") {
      return OPENSOURCEversions;
    } else {
      return [];
    }
  }

  useEffect(() => {
    dispatch(Action.getProvisioning());

    if (props.match.params.requestId) {
      dispatch(Actions.getRequest(props.match.params.requestId));
    }
  }, [dispatch]);

  if (!provisioning && user.role !== "requestor") {
    return null;
  }
  if (!request && props.match.path == "/requestor/viewrequest/:requestId") {
    return null;
  }
  if (!requestor && props.match.path.includes == "/viewrequest/") {
    return null;
  }

  if (request) {
    if (reRenderCounter) {
      const schema = handleSchemaFromApi(request);
      setForm(schema);
      setDisabledInputs(true);
      reRenderCounter = false;
    } else {
      reRenderCounter = true;
    }
  }

  if (provisioningExistence == false && user.role == "requestor") {
    return (
      <>
        <Box minHeight="1rem"></Box>
        <Container
          maxWidth={false}
          component={Box}
          marginTop="6rem"
          classes={{ root: classes.containerRoot }}
          marginBottom="90px"
        >
          <h1>Provisionig Template not yet set</h1>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${
          user.role == "requestor" && !request
            ? "Create Request"
            : "View Request"
        } | ${setup.organizationName}`}</title>
      </Helmet>
      <Box minHeight="1rem"></Box>

      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="6rem"
        classes={{ root: classes.containerRoot }}
        marginBottom="90px"
      >
        {request ? (
          <Card
            classes={{
              root: classes.cardRoot + " " + classes.cardRootSecondary,
            }}
            style={{
              marginBottom: "25px",
            }}
          >
            <CardHeader
              subheader={
                <Grid
                  container
                  component={Box}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs="auto">
                    <Box
                      component={Typography}
                      variant="h3"
                      style={{
                        color: "primary",
                      }}
                      marginBottom="0!important"
                    >
                      <Typography
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        Status
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs="auto">
                    <Button
                      variant="outlined"
                      style={{
                        color: "#000000",
                        background: "#FFFFFF",
                        border: "none",
                        height: "36px",
                        width: "120px",
                        boxShadow:
                          "0px 0px 1px rgba(0, 0, 0, 0.02), 0px 1px 3px rgba(50, 50, 93, 0.15)",
                      }}
                      size="small"
                      endIcon={
                        <Box
                          marginRight="10px"
                          width=".375rem"
                          height=".375rem"
                          borderRadius="50%"
                          display="inline-block"
                          className={`${
                            tableClasses.verticalAlignMiddle
                          } + " " + ${
                            (request && request.status == "pending") ||
                            (request && request.status == "approved")
                              ? tableClasses.bgPending
                              : (request && request.status == "rejected") ||
                                (request && request.status == "failed")
                              ? tableClasses.bgError
                              : tableClasses.bgSuccess
                          }`}
                        ></Box>
                      }
                    >
                      {request && request.status == "approved"
                        ? "Executing"
                        : request
                        ? capitalizeFirstLetter(request.status)
                        : ""}
                    </Button>
                  </Grid>
                </Grid>
              }
              style={{
                background: "#F7FAFC",
                paddingTop: "12px",
                paddingBottom: "12px",
              }}
            ></CardHeader>

            {(request && request.status == "approved") ||
            (request && request.status == "pending") ? (
              ""
            ) : (
              <CardContent>
                <div
                  className={classes.plLg4}
                  style={{
                    color: "#000000",
                  }}
                >
                  {request && request.status == "failed" ? (
                    <Box>
                      <h3>Reason of failure</h3>

                      <Typography>
                        {request ? request.failure.UCDMessage : ""}
                      </Typography>
                    </Box>
                  ) : request && request.status == "rejected" ? (
                    <Box>
                      <h3>Reason of rejection</h3>

                      <Typography>{request.rejectReason}</Typography>
                    </Box>
                  ) : request && request.status == "success" ? (
                    <Box>
                      <h3>Provisioned instances</h3>

                      <Grid container>
                        {request.success.appHostNames &&
                          request.success.appHostNames.length > 0 && (
                            <Grid item xs={12} lg={4}>
                              <TableContainer>
                                <TableBody>
                                  <TableRow>
                                    <TableCell style={{ border: "none" }}>
                                      <Typography
                                        style={{
                                          color: "#E15638",
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          lineHeight: "19px",
                                        }}
                                      >
                                        {" "}
                                        App Server{" "}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell style={{ border: "none" }}>
                                      <Typography
                                        style={{
                                          color: "#000000",
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          lineHeight: "19px",
                                        }}
                                      >
                                        {" "}
                                        Host Name{" "}
                                      </Typography>
                                    </TableCell>

                                    <TableCell style={{ border: "none" }}>
                                      <Typography
                                        style={{
                                          color: "#000000",
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          lineHeight: "19px",
                                        }}
                                      >
                                        {" "}
                                        IP Address{" "}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>

                                  {request.success.appHostNames.map(
                                    (name, i) => (
                                      <TableRow>
                                        <TableCell style={{ border: "none" }}>
                                          <Typography
                                            style={{
                                              color: "#000000",
                                              fontWeight: "normal",
                                              fontSize: "14px",
                                              lineHeight: "19px",
                                            }}
                                          >
                                            {name}
                                          </Typography>
                                        </TableCell>

                                        <TableCell style={{ border: "none" }}>
                                          <Typography
                                            style={{
                                              color: "#000000",
                                              fontWeight: "normal",
                                              fontSize: "14px",
                                              lineHeight: "19px",
                                            }}
                                          >
                                            {
                                              request.success.AppServers[name]
                                                .IPAddress1
                                            }
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </TableContainer>
                            </Grid>
                          )}
                        {request.success.webHostNames &&
                          request.success.webHostNames.length > 0 && (
                            <Grid item xs={12} lg={4}>
                              <TableContainer>
                                <TableBody>
                                  <TableRow>
                                    <TableCell style={{ border: "none" }}>
                                      <Typography
                                        style={{
                                          color: "#E15638",
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          lineHeight: "19px",
                                        }}
                                      >
                                        {" "}
                                        Web Server{" "}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>

                                  <TableRow>
                                    <TableCell style={{ border: "none" }}>
                                      <Typography
                                        style={{
                                          color: "#000000",
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          lineHeight: "19px",
                                        }}
                                      >
                                        {" "}
                                        Host Name{" "}
                                      </Typography>
                                    </TableCell>

                                    <TableCell style={{ border: "none" }}>
                                      <Typography
                                        style={{
                                          color: "#000000",
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          lineHeight: "19px",
                                        }}
                                      >
                                        {" "}
                                        IP Address{" "}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>

                                  {request.success.WebServers.webHostNames.map(
                                    (name, i) => (
                                      <TableRow>
                                        <TableCell style={{ border: "none" }}>
                                          <Typography
                                            style={{
                                              color: "#000000",
                                              fontWeight: "normal",
                                              fontSize: "14px",
                                              lineHeight: "19px",
                                            }}
                                          >
                                            {name}
                                          </Typography>
                                        </TableCell>

                                        <TableCell style={{ border: "none" }}>
                                          <Typography
                                            style={{
                                              color: "#000000",
                                              fontWeight: "normal",
                                              fontSize: "14px",
                                              lineHeight: "19px",
                                            }}
                                          >
                                            {
                                              request.success.WebServers[name]
                                                .IPAddress1
                                            }
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </TableContainer>
                            </Grid>
                          )}
                        <Grid item xs={12} lg={4}>
                          <TableContainer>
                            <TableBody>
                              <TableRow>
                                <TableCell style={{ border: "none" }}>
                                  <Typography
                                    style={{
                                      color: "#E15638",
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      lineHeight: "19px",
                                    }}
                                  >
                                    {" "}
                                    EPG Name{" "}
                                  </Typography>
                                </TableCell>
                              </TableRow>

                              {request.success.appHostNames &&
                                request.success.appHostNames.length > 0 && (
                                  <React.Fragment>
                                    <TableRow>
                                      <TableCell style={{ border: "none" }}>
                                        <Typography
                                          style={{
                                            color: "#000000",
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            lineHeight: "19px",
                                          }}
                                        >
                                          {" "}
                                          AppEPGName{" "}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>

                                    <TableRow>
                                      <TableCell style={{ border: "none" }}>
                                        <Typography
                                          style={{
                                            color: "#000000",
                                            fontWeight: "normal",
                                            fontSize: "14px",
                                            lineHeight: "19px",
                                          }}
                                        >
                                          {
                                            request.success.AppServers
                                              .AppEPGName
                                          }
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                )}
                              {request.success.webHostNames &&
                                request.success.webHostNames.length > 0 && (
                                  <React.Fragment>
                                    <TableRow>
                                      <TableCell style={{ border: "none" }}>
                                        <Typography
                                          style={{
                                            color: "#000000",
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            lineHeight: "19px",
                                          }}
                                        >
                                          {" "}
                                          WebEPGName{" "}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ border: "none" }}>
                                        <Typography
                                          style={{
                                            color: "#000000",
                                            fontWeight: "normal",
                                            fontSize: "14px",
                                            lineHeight: "19px",
                                          }}
                                        >
                                          {
                                            request.success.WebServers
                                              .WebEPGName
                                          }
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                )}
                            </TableBody>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}
                </div>
              </CardContent>
            )}
          </Card>
        ) : (
          ""
        )}

        <Card
          classes={{
            root: classes.cardRoot + " " + classes.cardRootSecondary,
          }}
        >
          <CardHeader
            subheader={
              <Grid
                container
                component={Box}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs="auto">
                  <Box
                    component={Typography}
                    variant="h3"
                    style={{
                      color: "primary",
                    }}
                    marginBottom="0!important"
                  >
                    <Typography color="primary" style={{ fontWeight: "bold" }}>
                      Request Form
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            }
            style={{
              background: "#F7FAFC",
            }}
          ></CardHeader>

          <CardContent>
            {provisioning ? (
              <div className={classes.plLg4}>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <FormLabel
                        style={{
                          fontWeight: "normal",
                          width: "190px",
                          marginTop: "10px",
                        }}
                      >
                        Status
                      </FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                          type="text"
                          name="status"
                          value={provisioning.status}
                          disabled
                          placeholder="Status"
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <FormLabel
                        style={{
                          fontWeight: "normal",
                          width: "190px",
                          marginTop: "10px",
                        }}
                      >
                        ARN No
                      </FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                          type="text"
                          value={provisioning.arnNo}
                          disabled
                          placeholder="ARN No"
                        />
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <FormLabel
                        style={{
                          fontWeight: "normal",
                          width: "190px",
                          marginTop: "10px",
                        }}
                      >
                        Requestor
                      </FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                          type="text"
                          disabled
                          name="requestor"
                          onChange={handleChange}
                          value={
                            user.role == "requestor" &&
                            props.match.path == "/requestor/createrequest"
                              ? `${user.firstName} ${user.lastName}`
                              : requestor
                              ? `${requestor.firstName} ${requestor.lastName}`
                              : ""
                          }
                          placeholder="Requestor"
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <FormLabel
                        style={{
                          fontWeight: "normal",
                          width: "190px",
                          lineHeight: "19px",
                          marginTop: "5px",
                        }}
                      >
                        Application Name
                      </FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                          type="text"
                          disabled
                          placeholder="Application Name"
                          value={provisioning.applicationName}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <FormLabel
                        style={{
                          fontWeight: "normal",
                          width: "190px",
                          marginTop: "10px",
                        }}
                      >
                        Category
                      </FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                          disabled
                          value={provisioning.category}
                          type="text"
                          placeholder="Category"
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <FormLabel
                        style={{
                          fontWeight: "normal",
                          width: "190px",
                          marginTop: "8px",
                        }}
                      >
                        Sub Application Name
                      </FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                          type="text"
                          disabled
                          value={provisioning.subApplicationName}
                          placeholder="Sub Application Name"
                        />
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <FormLabel
                        style={{
                          fontWeight: "normal",
                          width: "190px",
                          marginTop: "10px",
                        }}
                      >
                        Sub Category
                      </FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                          type="text"
                          disabled
                          value={provisioning.subCategory}
                          placeholder="Sub Category"
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <FormLabel
                        style={{
                          fontWeight: "normal",
                          width: "190px",
                          marginTop: "10px",
                        }}
                      >
                        Tier
                      </FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                          type="text"
                          disabled
                          value={provisioning.tier}
                          placeholder="Tier"
                        />
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <FormLabel
                        style={{
                          fontWeight: "normal",
                          width: "190px",
                          marginTop: "10px",
                        }}
                      >
                        Classification
                      </FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          disabled
                          value={provisioning.classification}
                          autoComplete="off"
                          type="text"
                          placeholder="Classification"
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <FormLabel
                        style={{
                          fontWeight: "normal",
                          width: "190px",
                          marginTop: "10px",
                        }}
                      >
                        Datacenter
                      </FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                          type="text"
                          disabled
                          value={provisioning.dataCenter}
                          placeholder="Datacenter"
                        />
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <FormLabel
                        style={{
                          fontWeight: "normal",
                          width: "190px",
                          marginTop: "10px",
                        }}
                      >
                        Company
                      </FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                          type="text"
                          disabled
                          value={provisioning.company}
                          placeholder="Company"
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <FormLabel
                        style={{
                          fontWeight: "normal",
                          width: "190px",
                          marginTop: "8px",
                        }}
                      >
                        Recovery  Datacenter
                      </FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                          type="text"
                          disabled
                          value={provisioning.recovery}
                          placeholder="Recovery Datacenter"
                        />
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} lg={6}></Grid>
                  <Grid item xs={12} lg={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <FormLabel
                        style={{
                          fontWeight: "normal",
                          width: "190px",
                          marginTop: "10px",
                        }}
                      >
                        NDR Datacenter
                      </FormLabel>
                      <FormControl
                        variant="filled"
                        component={Box}
                        width="100%"
                        marginBottom="1rem!important"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                          disabled
                          value={provisioning.ndr}
                          type="text"
                          placeholder="NDR Datacenter"
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <FormLabel
                        style={{
                          fontWeight: "normal",
                          width: "190px",
                          marginTop: "10px",
                        }}
                      >
                        Deployment Environment
                      </FormLabel>
                      <FormControl fullWidth>
                        <Select
                          IconComponent={(props) => (
                            <ExpandMoreIcon {...props} />
                          )}
                          input={
                            <BootstrapInput
                              name="deploymentEnvironment"
                              onChange={handleChange}
                            />
                          }
                          value={form.deploymentEnvironment}
                        >
                          <MenuItem value="UAT" selected>
                            UAT Setup
                          </MenuItem>
                          <MenuItem value="Pre-Production Setup" disabled>
                            Pre-Production Setup
                          </MenuItem>
                          <MenuItem value="Primary Datacenter" disabled>
                            Primary Datacenter
                          </MenuItem>
                          <MenuItem value="Recovery Datacenter" disabled>
                            Recovery Datacenter
                          </MenuItem>
                          <MenuItem value="NDR Datacenter" disabled>
                            NDR Datacenter
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </div>
            ) : (
              ""
            )}

            {provisioning ? (
              <div className={classes.plLg4}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    bgcolor: "#F6F9FC",
                    borderRadius: 1,
                  }}
                  style={{ paddingTop: "5px", marginTop: "50px" }}
                >
                  <Typography
                    style={{ paddingLeft: "80px", paddingTop: "10px" }}
                  >
                    Deployment Scope
                  </Typography>

                  <FormControlLabel
                    style={{ paddingLeft: "40px" }}
                    control={
                      <Checkbox
                        checked
                        checked={form.serverProvisioning}
                        onChange={handleChange}
                        value={form.serverProvisioning}
                        name="serverProvisioning"
                        color="primary"
                      />
                    }
                    label="Server Provisioning"
                  />

                  <FormControlLabel
                    style={{ paddingLeft: "40px" }}
                    control={
                      <Checkbox
                        checked
                        checked={form.productProvisioning}
                        onChange={handleChange}
                        value={form.productProvisioning}
                        name="productProvisioning"
                        color="primary"
                      />
                    }
                    label="Product Provisioning"
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    bgcolor: "#FCFCFC",
                    borderRadius: 1,
                  }}
                  style={{ paddingTop: "5px", marginTop: "10px" }}
                >
                  <Typography
                    style={{ paddingLeft: "80px", paddingTop: "10px" }}
                  >
                    Server
                  </Typography>

                  <FormControlLabel
                    style={{ paddingLeft: "128px" }}
                    control={
                      <Checkbox
                        checked={form.appServer}
                        onChange={handleChange}
                        value={form.appServer}
                        name="appServer"
                        color="primary"
                      />
                    }
                    label="App Server"
                  />

                  <FormControlLabel
                    style={{ paddingLeft: "40px" }}
                    control={
                      <Checkbox
                        checked
                        checked={form.webServer}
                        onChange={handleChange}
                        value={form.webServer}
                        name="webServer"
                        color="primary"
                      />
                    }
                    label="Web Server"
                  />

                  <FormControlLabel
                    style={{ paddingLeft: "40px" }}
                    control={
                      <Checkbox
                        checked
                        checked={form.dbServer}
                        onChange={handleChange}
                        value={form.dbServer}
                        name="dbServer"
                        color="primary"
                      />
                    }
                    label="DB Server"
                  />
                </Box>
              </div>
            ) : (
              ""
            )}

            <Box
              display={!form.serverProvisioning ? "none" : "flex"}
              sx={{
                justifyContent: "center",

                bgcolor: "#F46240",
                borderRadius: 1,
              }}
              style={{
                paddingTop: "5px",
                marginLeft: "-25px",
                marginRight: "-25px",
                marginTop: "40px",
              }}
            >
              <Typography style={{ padding: "5px", color: "white" }}>
                Server Provisioning
              </Typography>
            </Box>

            <Grid container item xs={12} lg={12}>
              <Grid
                hidden={!form.appServer || !form.serverProvisioning}
                item
                xs={12}
                lg={5}
                style={{
                  borderRight: "2px solid #F0F0F0",
                  background: "#FCFCFC",
                  marginLeft: "-40px",
                  paddingLeft: "30px",
                }}
              >
                <Container
                  style={{
                    margin: "20px",
                    paddingLeft: "85px",
                  }}
                  item
                  xs={12}
                  lg={6}
                >
                  <Typography>App Server</Typography>
                </Container>
                <div
                  style={{
                    display: "flex",

                    marginTop: "35px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Server Type
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"virtual"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="serverTypeA"
                          onChange={handleChange}
                        />
                      }
                      value={form.serverTypeA}
                    >
                      <MenuItem value="virtual" selected>
                        Virtual
                      </MenuItem>
                      <MenuItem value="physical" selected>
                        Physical
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "20px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Number Of Instances
                  </FormLabel>

                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"virtual"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="numberOfInstancesA"
                          onChange={handleChange}
                        />
                      }
                      value={form.numberOfInstancesA}
                    >
                      <MenuItem value={1} selected>
                        1
                      </MenuItem>
                      <MenuItem value={2} selected>
                        2
                      </MenuItem>
                      <MenuItem value={3} selected>
                        3
                      </MenuItem>
                      <MenuItem value={4} selected>
                        4
                      </MenuItem>
                      <MenuItem value={5} selected>
                        5
                      </MenuItem>
                      <MenuItem value={6} selected>
                        6
                      </MenuItem>
                      <MenuItem value={7} selected>
                        7
                      </MenuItem>
                      <MenuItem value={8} selected>
                        8
                      </MenuItem>
                      <MenuItem value={9} selected>
                        9
                      </MenuItem>
                      <MenuItem value={10} selected>
                        10
                      </MenuItem>
                      <MenuItem value={11} selected>
                        11
                      </MenuItem>
                      <MenuItem value={12} selected>
                        12
                      </MenuItem>
                      <MenuItem value={13} selected>
                        13
                      </MenuItem>
                      <MenuItem value={14} selected>
                        14
                      </MenuItem>
                      <MenuItem value={15} selected>
                        15
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "1px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    OS Type
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="osTypeA"
                          onChange={(e) => {
                            form.osVersionA = "";
                            handleChange(e);
                          }}
                        />
                      }
                      value={form.osTypeA}
                    >
                      <MenuItem value={"AIX"}>AIX</MenuItem>
                      <MenuItem value={"Linux"}>Linux</MenuItem>
                      <MenuItem value={"Solaris"}>Solaris</MenuItem>
                      <MenuItem value={"Windows"}>Windows</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "1px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    OS Version
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="osVersionA"
                          onChange={handleChange}
                        />
                      }
                      value={form.osVersionA}
                    >
                      {appServerOsVersion(form.osTypeA).map((data, i) => (
                        <MenuItem value={data}>{data}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "1px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Number Of CPUs
                  </FormLabel>

                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"virtual"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="numberOfCpuA"
                          onChange={handleChange}
                        />
                      }
                      value={form.numberOfCpuA}
                    >
                      <MenuItem value={2} selected>
                        2
                      </MenuItem>

                      <MenuItem value={4} selected>
                        4
                      </MenuItem>

                      <MenuItem value={6} selected>
                        6
                      </MenuItem>

                      <MenuItem value={8} selected>
                        8
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "1px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Memory in GB
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Box
                      paddingLeft="0.75rem"
                      paddingRight="0.75rem"
                      component={FilledInput}
                      disabled
                      autoComplete="off"
                      type="text"
                      name="memoryInGbA"
                      onChange={handleChange}
                      value={4 * form.numberOfCpuA}
                      placeholder="16"
                    />
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "1px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "5px",
                    }}
                  >
                    Mount Point / Application Drive    In GB
                  </FormLabel>

                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"virtual"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="mountPointInGbA"
                          onChange={handleChange}
                        />
                      }
                      value={form.mountPointInGbA}
                    >
                      <MenuItem value={50} selected>
                        50
                      </MenuItem>

                      <MenuItem value={100} selected>
                        100
                      </MenuItem>

                      <MenuItem value={200} selected>
                        200
                      </MenuItem>

                      <MenuItem value={300} selected>
                        300
                      </MenuItem>

                      <MenuItem value={500} selected>
                        500
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "1px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Servers Required in UAT DMZ
                  </FormLabel>

                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"virtual"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="serversRequiredInUatDmzA"
                          onChange={handleChange}
                        />
                      }
                      value={form.serversRequiredInUatDmzA}
                    >
                      <MenuItem value={0} selected>
                        0
                      </MenuItem>
                      <MenuItem value={1} selected>
                        1
                      </MenuItem>
                      <MenuItem value={2} selected>
                        2
                      </MenuItem>
                      <MenuItem value={3} selected>
                        3
                      </MenuItem>
                      <MenuItem value={4} selected>
                        4
                      </MenuItem>
                      <MenuItem value={5} selected>
                        5
                      </MenuItem>
                      <MenuItem value={6} selected>
                        6
                      </MenuItem>
                      <MenuItem value={7} selected>
                        7
                      </MenuItem>
                      <MenuItem value={8} selected>
                        8
                      </MenuItem>
                      <MenuItem value={9} selected>
                        9
                      </MenuItem>
                      <MenuItem value={10} selected>
                        10
                      </MenuItem>
                      <MenuItem value={11} selected>
                        11
                      </MenuItem>
                      <MenuItem value={12} selected>
                        12
                      </MenuItem>
                      <MenuItem value={13} selected>
                        13
                      </MenuItem>
                      <MenuItem value={14} selected>
                        14
                      </MenuItem>
                      <MenuItem value={15} selected>
                        15
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "1px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Non DMZ Servers required
                  </FormLabel>

                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"virtual"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="nonDmzServersRequiredA"
                          onChange={handleChange}
                        />
                      }
                      value={form.nonDmzServersRequiredA}
                    >
                      <MenuItem value={0} selected>
                        0
                      </MenuItem>
                      <MenuItem value={1} selected>
                        1
                      </MenuItem>
                      <MenuItem value={2} selected>
                        2
                      </MenuItem>
                      <MenuItem value={3} selected>
                        3
                      </MenuItem>
                      <MenuItem value={4} selected>
                        4
                      </MenuItem>
                      <MenuItem value={5} selected>
                        5
                      </MenuItem>
                      <MenuItem value={6} selected>
                        6
                      </MenuItem>
                      <MenuItem value={7} selected>
                        7
                      </MenuItem>
                      <MenuItem value={8} selected>
                        8
                      </MenuItem>
                      <MenuItem value={9} selected>
                        9
                      </MenuItem>
                      <MenuItem value={10} selected>
                        10
                      </MenuItem>
                      <MenuItem value={11} selected>
                        11
                      </MenuItem>
                      <MenuItem value={12} selected>
                        12
                      </MenuItem>
                      <MenuItem value={13} selected>
                        13
                      </MenuItem>
                      <MenuItem value={14} selected>
                        14
                      </MenuItem>
                      <MenuItem value={15} selected>
                        15
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                hidden={!form.webServer || !form.serverProvisioning}
                lg={5}
                style={{
                  borderRight: "2px solid #F0F0F0",

                  marginLeft: "20px",
                }}
              >
                <Container
                  style={{
                    margin: "20px",
                    paddingLeft: "85px",
                  }}
                  item
                  xs={12}
                  lg={6}
                >
                  <Typography>Web Server</Typography>
                </Container>
                <div
                  style={{
                    display: "flex",

                    marginTop: "30px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Server Type
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"virtual"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="serverTypeW"
                          onChange={handleChange}
                        />
                      }
                      value={form.serverTypeW}
                    >
                      <MenuItem value={"virtual"}>Virtual</MenuItem>
                      <MenuItem value="physical" selected>
                        Physical
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "20px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Number Of Instances
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"virtual"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="numberOfInstancesW"
                          onChange={handleChange}
                        />
                      }
                      value={form.numberOfInstancesW}
                    >
                      <MenuItem value={1} selected>
                        1
                      </MenuItem>
                      <MenuItem value={2} selected>
                        2
                      </MenuItem>
                      <MenuItem value={3} selected>
                        3
                      </MenuItem>
                      <MenuItem value={4} selected>
                        4
                      </MenuItem>
                      <MenuItem value={5} selected>
                        5
                      </MenuItem>
                      <MenuItem value={6} selected>
                        6
                      </MenuItem>
                      <MenuItem value={7} selected>
                        7
                      </MenuItem>
                      <MenuItem value={8} selected>
                        8
                      </MenuItem>
                      <MenuItem value={9} selected>
                        9
                      </MenuItem>
                      <MenuItem value={10} selected>
                        10
                      </MenuItem>
                      <MenuItem value={11} selected>
                        11
                      </MenuItem>
                      <MenuItem value={12} selected>
                        12
                      </MenuItem>
                      <MenuItem value={13} selected>
                        13
                      </MenuItem>
                      <MenuItem value={14} selected>
                        14
                      </MenuItem>
                      <MenuItem value={15} selected>
                        15
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "1px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    OS Type
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="osTypeW"
                          onChange={(e) => {
                            form.osTypeW = "";
                            handleChange(e);
                          }}
                        />
                      }
                      value={form.osTypeW}
                    >
                      <MenuItem value={"AIX"}>AIX</MenuItem>
                      <MenuItem value={"Linux"}>Linux</MenuItem>
                      <MenuItem value={"Solaris"}>Solaris</MenuItem>
                      <MenuItem value={"Windows"}>Windows</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "1px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    OS Version
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="osVersionW"
                          onChange={handleChange}
                        />
                      }
                      value={form.osVersionW}
                    >
                      {appServerOsVersion(form.osTypeW).map((data, i) => (
                        <MenuItem value={data}>{data}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "1px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Number Of CPUs
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"virtual"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="numberOfCpuW"
                          onChange={handleChange}
                        />
                      }
                      value={form.numberOfCpuW}
                    >
                      <MenuItem value={2} selected>
                        2
                      </MenuItem>

                      <MenuItem value={4} selected>
                        4
                      </MenuItem>

                      <MenuItem value={6} selected>
                        6
                      </MenuItem>

                      <MenuItem value={8} selected>
                        8
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "1px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Memory in GB
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Box
                      paddingLeft="0.75rem"
                      paddingRight="0.75rem"
                      component={FilledInput}
                      autoComplete="off"
                      disabled
                      name="memoryInGbW"
                      onChange={handleChange}
                      value={4 * form.numberOfCpuW}
                      type="text"
                      placeholder="167"
                    />
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "1px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "5px",
                    }}
                  >
                    Mount Point /    Application Drive    In GB
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"virtual"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="mountPointInGbW"
                          onChange={handleChange}
                        />
                      }
                      value={form.mountPointInGbW}
                    >
                      <MenuItem value={50} selected>
                        50
                      </MenuItem>

                      <MenuItem value={100} selected>
                        100
                      </MenuItem>

                      <MenuItem value={200} selected>
                        200
                      </MenuItem>

                      <MenuItem value={300} selected>
                        300
                      </MenuItem>

                      <MenuItem value={500} selected>
                        500
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "1px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Servers Required in  UAT DMZ
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"virtual"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="serversRequiredInUatDmzW"
                          onChange={handleChange}
                        />
                      }
                      value={form.serversRequiredInUatDmzW}
                    >
                      <MenuItem value={0} selected>
                        0
                      </MenuItem>
                      <MenuItem value={1} selected>
                        1
                      </MenuItem>
                      <MenuItem value={2} selected>
                        2
                      </MenuItem>
                      <MenuItem value={3} selected>
                        3
                      </MenuItem>
                      <MenuItem value={4} selected>
                        4
                      </MenuItem>
                      <MenuItem value={5} selected>
                        5
                      </MenuItem>
                      <MenuItem value={6} selected>
                        6
                      </MenuItem>
                      <MenuItem value={7} selected>
                        7
                      </MenuItem>
                      <MenuItem value={8} selected>
                        8
                      </MenuItem>
                      <MenuItem value={9} selected>
                        9
                      </MenuItem>
                      <MenuItem value={10} selected>
                        10
                      </MenuItem>
                      <MenuItem value={11} selected>
                        11
                      </MenuItem>
                      <MenuItem value={12} selected>
                        12
                      </MenuItem>
                      <MenuItem value={13} selected>
                        13
                      </MenuItem>
                      <MenuItem value={14} selected>
                        14
                      </MenuItem>
                      <MenuItem value={15} selected>
                        15
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",

                    marginTop: "1px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Non DMZ Servers required
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"virtual"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="nonDmzServersRequiredW"
                          onChange={handleChange}
                        />
                      }
                      value={form.nonDmzServersRequiredW}
                    >
                      <MenuItem value={0} selected>
                        0
                      </MenuItem>
                      <MenuItem value={1} selected>
                        1
                      </MenuItem>
                      <MenuItem value={2} selected>
                        2
                      </MenuItem>
                      <MenuItem value={3} selected>
                        3
                      </MenuItem>
                      <MenuItem value={4} selected>
                        4
                      </MenuItem>
                      <MenuItem value={5} selected>
                        5
                      </MenuItem>
                      <MenuItem value={6} selected>
                        6
                      </MenuItem>
                      <MenuItem value={7} selected>
                        7
                      </MenuItem>
                      <MenuItem value={8} selected>
                        8
                      </MenuItem>
                      <MenuItem value={9} selected>
                        9
                      </MenuItem>
                      <MenuItem value={10} selected>
                        10
                      </MenuItem>
                      <MenuItem value={11} selected>
                        11
                      </MenuItem>
                      <MenuItem value={12} selected>
                        12
                      </MenuItem>
                      <MenuItem value={13} selected>
                        13
                      </MenuItem>
                      <MenuItem value={14} selected>
                        14
                      </MenuItem>
                      <MenuItem value={15} selected>
                        15
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>

            <Box
              display={!form.productProvisioning ? "none" : "flex"}
              sx={{
                justifyContent: "center",

                bgcolor: "#F46240",
                borderRadius: 1,
              }}
              style={{
                paddingTop: "5px",
                marginLeft: "-25px",
                marginRight: "-25px",
                marginTop: "40px",
              }}
            >
              <Typography style={{ padding: "5px", color: "white" }}>
                Product Installation
              </Typography>
            </Box>

            <Grid container item xs={12} lg={12}>
              <Grid
                item
                xs={12}
                hidden={!form.appServer || !form.productProvisioning}
                lg={5}
                style={{
                  borderRight: "2px solid #F0F0F0",
                  background: "#FCFCFC",
                  marginLeft: "-40px",
                  paddingLeft: "30px",
                }}
              >
                <Container
                  style={{
                    margin: "20px",
                    paddingLeft: "85px",
                  }}
                  item
                  xs={12}
                  lg={6}
                >
                  <Typography>App Server</Typography>
                </Container>
                <div
                  style={{
                    display: "flex",

                    marginTop: "35px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Product OEM
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"oracle"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="productOemA"
                          onChange={(e) => {
                            form.productVersionA = "";
                            handleChange(e);
                          }}
                        />
                      }
                      value={form.productOemA}
                    >
                      <MenuItem value={"Oracle"}>Oracle</MenuItem>
                      <MenuItem value={"IBM"}>IBM</MenuItem>
                      <MenuItem value={"Microsoft"}>Microsoft</MenuItem>
                      <MenuItem value={"Opensource"}>Opensource</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Product Name
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Box
                      paddingLeft="0.75rem"
                      paddingRight="0.75rem"
                      component={FilledInput}
                      autoComplete="off"
                      type="text"
                      name="productNameA"
                      disabled
                      onChange={handleChange}
                      value={
                        form.productOemA == "Oracle"
                          ? "Weblogic"
                          : form.productOemA == "Microsoft"
                          ? "IIS"
                          : form.productOemA == "IBM"
                          ? "Websphere"
                          : form.productOemA == "Opensource"
                          ? "Tomcat"
                          : ""
                      }
                    />
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Product Version
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"oracle"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="productVersionA"
                          onChange={handleChange}
                        />
                      }
                      value={form.productVersionA}
                    >
                      {productInstallationOsVersion(form.productOemA).map(
                        (data, i) => (
                          <MenuItem value={data}>{data}</MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Mount Point Name
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Box
                      paddingLeft="0.75rem"
                      paddingRight="0.75rem"
                      component={FilledInput}
                      autoComplete="off"
                      name="mountPointA"
                      onChange={handleChange}
                      value={form.mountPointA}
                      type="text"
                      disabled={disableInputs}
                    />
                  </FormControl>
                </div>
              </Grid>

              <Grid
                hidden={!form.webServer || !form.productProvisioning}
                item
                xs={12}
                lg={5}
                style={{
                  borderRight: "2px solid #F0F0F0",

                  marginLeft: "20px",
                }}
              >
                <Container
                  style={{
                    margin: "20px",
                    paddingLeft: "85px",
                  }}
                  item
                  xs={12}
                  lg={6}
                >
                  <Typography>Web Server</Typography>
                </Container>

                <div
                  style={{
                    display: "flex",

                    marginTop: "35px",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Product OEM
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"oracle"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="productOemW"
                          onChange={(e) => {
                            form.productVersionW = "";
                            handleChange(e);
                          }}
                        />
                      }
                      value={form.productOemW}
                    >
                      <MenuItem value={"Oracle"}>Oracle</MenuItem>
                      <MenuItem value={"IBM"}>IBM</MenuItem>
                      <MenuItem value={"Microsoft"}>Microsoft</MenuItem>
                      <MenuItem value={"Opensource"}>Opensource</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Product Name
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Box
                      paddingLeft="0.75rem"
                      paddingRight="0.75rem"
                      component={FilledInput}
                      autoComplete="off"
                      disabled
                      name="productNameW"
                      onChange={handleChange}
                      value={
                        form.productOemW == "Oracle"
                          ? "Weblogic"
                          : form.productOemW == "Microsoft"
                          ? "IIS"
                          : form.productOemW == "IBM"
                          ? "Websphere"
                          : form.productOemW == "Opensource"
                          ? "Tomcat"
                          : ""
                      }
                      type="text"
                    />
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Product Version
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Select
                      value={"oracle"}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      input={
                        <BootstrapInput
                          disabled={disableInputs}
                          name="productVersionW"
                          onChange={handleChange}
                        />
                      }
                      value={form.productVersionW}
                    >
                      {productInstallationOsVersion(form.productOemW).map(
                        (data, i) => (
                          <MenuItem value={data}>{data}</MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <FormLabel
                    style={{
                      fontWeight: "normal",
                      width: "290px",
                      marginTop: "10px",
                    }}
                  >
                    Mount Point Name
                  </FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Box
                      paddingLeft="0.75rem"
                      paddingRight="0.75rem"
                      component={FilledInput}
                      autoComplete="off"
                      name="mountPointW"
                      onChange={handleChange}
                      value={form.mountPointW}
                      type="text"
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {request && request.status == "pending" && user.role == "approver" ? (
          <Container>
            <Grid
              container
              style={{
                marginTop: "90px",
              }}
            >
              <Grid item xs={12}>
                <FormGroup>
                  <FormLabel>Give a reason for declining this form</FormLabel>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <Box
                      paddingLeft="0.75rem"
                      paddingRight="0.75rem"
                      component={FilledInput}
                      autoComplete="off"
                      name="reason"
                      value={reason}
                      onChange={handleReasonChange}
                      multiline
                      placeholder="Enter your reason here"
                      rows="4"
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: "#F6F9FC",
                borderRadius: 1,
              }}
              style={{ paddingTop: "5px", marginTop: "50px" }}
            >
              <Button
                onClick={handleDecline}
                style={{
                  paddingLeft: "50px",
                  marginRight: "30px",
                  paddingRight: "50px",
                  borderRadius: "5px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                }}
                variant="outlined"
                color="primary"
              >
                Decline
              </Button>
              <Button
                onClick={handleApprove}
                style={{
                  background: "#e30c57",
                  border: "#e30c57",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  borderRadius: "5px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                }}
                variant="contained"
                color="primary"
              >
                Approve
              </Button>
            </Box>
          </Container>
        ) : (
          ""
        )}

        {user.role == "requestor" &&
        props.match.path !== "/requestor/viewrequest/:requestId" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              bgcolor: "#F6F9FC",
              borderRadius: 1,
            }}
            style={{ paddingTop: "5px", marginTop: "50px" }}
          >
            <Button
              style={{
                paddingLeft: "80px",
                marginRight: "30px",
                paddingRight: "80px",
                borderRadius: "5px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              }}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitRequest}
              style={{
                background: "#e30c57",
                border: "#e30c57",
                paddingLeft: "40px",
                paddingRight: "40px",
                borderRadius: "5px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              }}
              variant="contained"
              color="primary"
            >
              Submit Request
            </Button>
          </Box>
        ) : (
          ""
        )}
      </Container>
    </>
  );
}

export default ViewRequest;
