import * as Actions from '../../actions/helper';

const initialState = {
    show : false,
    message : null,
    severity : null
};

const message = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.SHOW_MESSAGE:
        {
            return {
                show  : true,
                message : action.message,
                severity : action.severity
            };
        }
        case Actions.HIDE_MESSAGE:
        {
            return {
                show: false,
               
            };
        }
        default:
        {
            return state;
        }
    }
};

export default message;