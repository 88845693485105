import React, {useEffect, useRef, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Typography } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Snackbar, SnackbarContent } from "@material-ui/core";

// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";
import Add  from "@material-ui/icons/Add";

// core components
import componentStyles from "assets/theme/views/auth/login.js";

import useForm from "utils/useForm";
import {useDispatch, useSelector} from 'react-redux';
import Formsy from 'formsy-react';
import * as Action from '../../store/actions/auth';
import { Helmet } from 'react-helmet'

const useStyles = makeStyles(componentStyles);

function ForgotPassword(props) {
  const classes = useStyles();
  const theme = useTheme();

  const defaultForm = {
    email: '',
    otp: ''
  };

  const [showSnack, setShowSnack] = useState(false);
  const [sendCounter, setSendCounter] = useState(1);
  const setup = useSelector(({setup}) => setup.setup);
  const {form, handleChange, resetForm} = useForm(defaultForm);
  const dispatch = useDispatch();

  const formRef = useRef(null);

  const checkError = useSelector(({auth}) => auth.login.error);
  const checkSuccess = useSelector(({auth}) => auth.login.success);

  

  function handleSubmit(event)
  {

     
    dispatch(Action.sendOtp({email:form.email}, props.history))

  }

  function handleVerification(event)
  {
     
    dispatch(Action.verifyOtp(form, props.history))

  }

  useEffect(() => {

   

  });

  return (
    <>
        <Helmet>
          <title>{`Forgot Password | ${setup.organizationName}`}</title>
        </Helmet>
      <Grid >
      
        <Card style={{
            boxShadow: "0px -2px 4px rgba(234, 234, 234, 0.25), 0px 2px 8px rgba(50, 50, 93, 0.15)", borderRadius: "6px"
          }}>
            <CardHeader subheader={<Typography>Forgot Password ?</Typography>} style={{
                border: '1px solid white'
            }}></CardHeader>
          
          <CardContent classes={{ root: classes.cardContent }}>

              

          

            <Box
              color={theme.palette.gray[600]}
              textAlign="center"
              marginBottom="1rem"
              marginTop="-1.5rem"
              fontSize="1rem"
            >
              <Box fontSize="80%" fontWeight="400" component="small">
                {checkError ? <Typography style={{color:"#B13021"}}> {checkError} </Typography>  : ''}
                
              </Box>
            </Box>
           
          <Formsy
                onValidSubmit={handleVerification}
                ref={formRef}                   
            >
            
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <FilledInput
                autoComplete="off"
                name="email"
                type="email"
                required
                value={form.email}
                
                onChange={handleChange}
                placeholder="Email"
                endAdornment={
                    <InputAdornment position="end"  >
                      <Button color="primary" style={
                          {maxWidth: '140px', fontSize: '10px', lineHeight: "10px", maxHeight: '30px' }
                      } 
                      variant='outlined' 
                      onClick={() => {
                        setSendCounter(2);
                        handleSubmit();
                      }}
                     
                      > { sendCounter == 1 ? 'Send OTP' : 'Resend' } </Button>
                    </InputAdornment>
                  }
              />
            </FormControl>
            <FormControl
              variant="filled"
              component={Box}
              width="100%"
              marginBottom="1rem!important"
            >
              <FilledInput
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <img src={require("../../assets/img/icons/common/eye.png")} />
                  </InputAdornment>
                }
                type="number"
                name="otp"
                value={form.otp}
                required
                onChange={handleChange}
                placeholder="OTP"
                
              />
            </FormControl>
            
           
            <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
            <Button style={{background: '#e30c57', border: '#e30c57', paddingLeft: '40px' ,
              paddingRight: '40px', borderRadius: '5px',
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)"}} type="submit" variant="contained" >
                CONTINUE
              </Button>
            </Box>

            </Formsy>
          </CardContent>
        </Card>
        
      </Grid>
    </>
  );
}

export default ForgotPassword;
