import axios from "axios";
import * as snackAction from 'store/actions/helper';
export const CREATE_REQUEST = 'CREATE_REQUEST';


export function createRequest(model, props)
{

    return (dispatch) =>
        axios
            .post(`api/v1/request/createrequest`, model)
            .then(res => {  
                dispatch(snackAction.showMessage('Request created successfully', "success"));
                dispatch({
                    type   : CREATE_REQUEST,
                    payload: res.data.request
                });
                props.history.push('/requestor/resourcerequests')

            }).catch(error => {
                dispatch(snackAction.showMessage(error.message, "error"));
                console.log(error)

        })
}