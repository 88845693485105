import axios from "axios";
import * as Action from '../provisioning';
export const GET_REQUEST = 'GET_REQUEST';


export function getRequest(requestId)
{

    return (dispatch) =>
        axios
            .get(`api/v1/request/getrequest/${requestId}`)
            .then(res => {  

                dispatch({
                    type: Action.GET_PROVISIONING,
                    payload: res.data.request.provisioning
                });

                dispatch({
                    type   : GET_REQUEST,
                    payload: res.data.request,
                    requestor: res.data.requestor
                });

            }).catch(error => {

                console.log(error)

        })
}