import React, { useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import * as Action from "store/actions/helper";
// core components
import AuthHeader from "views/common/Headers/AuthHeader.js";
import { Typography } from "@material-ui/core";
import routes from "routes.js";
import { useHistory } from "react-router-dom";
import componentStyles from "assets/theme/layouts/auth.js";
import { customHistory } from "index";
import Alert from "@mui/material/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Snackbar } from "@material-ui/core";
const useStyles = makeStyles(componentStyles);

const Auth = (layoutProp) => {
  const classes = useStyles();
  const mainContent = React.useRef(null);
  const location = useLocation();
  const user = useSelector(({ auth }) => auth.user.data);
  const [checkRoute, setCheckRoute] = useState(null);
  let history = useHistory();
  const theme = createTheme();

  var APIUrl = process.env.REACT_APP_API_BASE_URL + "/uploads/";

  function checkTitle() {
    let obj = routes.find(
      (o) => o.path === layoutProp.history.location.pathname.slice(5)
    );

    if (obj) {
      return obj.name;
    }
  }

  React.useEffect(() => {
    document.body.classList.add(classes.bgDefault);
    return () => {
      document.body.classList.remove(classes.bgDefault);
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const dispatch = useDispatch();
  const checkMessage = useSelector(({ helper }) => helper.message.show);
  const checkMessageText = useSelector(({ helper }) => helper.message.message);
  const checkMessageSeverity = useSelector(
    ({ helper }) => helper.message.severity
  );
  const setup = useSelector(({ setup }) => setup.setup);

  function hideMessage() {
    dispatch({
      type: Action.HIDE_MESSAGE,
    });
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };



  return (
    <>
      <Snackbar
        open={checkMessage}
        autoHideDuration={3000}
        onClose={() => {
          hideMessage();
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          sx={{ width: "100%" }}
          variant="filled"
          severity={checkMessageSeverity}
        >
          {checkMessageText}
        </Alert>
      </Snackbar>
      <div
        className="main-content"
        ref={mainContent}
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Grid container component="main">
          <Grid
            item
            xs={0}
            sm={6}
            md={6}
            style={{
              background:
                "linear-gradient(66.52deg, #e30c57 -1.06%, #e30c57d4 79.42%)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "100vh", paddingTop: "70px" }}
            >
              <Typography
                variant="h1"
                component="h1"
                style={{
                  paddingTop: "30px",
                  color: "white",
                }}
              >
                {checkTitle()}
              </Typography>

              <Grid
                container
                justify="center"
                style={{
                  paddingTop: "30px",
                }}
              >
                <img
                  width="80%"
                  src={require("assets/img/brand/group_43.png")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 2,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid
                container
                justify="center"
                style={{ paddingTop: "30px", paddingBottom: "30px" }}
              >
                <img
                  width="30%"
                  src={
                    setup && setup.customerLogo
                      ? `${APIUrl}/${setup.customerLogo}`
                      : require("assets/img/brand/Datamato_Logo.png")
                  }
                />
              </Grid>

              <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to="/auth/login" />
              </Switch>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Auth;
