
import axios from 'axios';

import * as Action from '../helper/index';
import * as Actions from './login.actions';
export const SETUP_SUCCESS = 'SETUP_SUCCESS';


export function setup(form)
{
    
    let bodyFormData = new FormData();

    bodyFormData.append('organizationName', form.organizationName);
    bodyFormData.append('registeredAddress', form.registeredAddress);
    bodyFormData.append('aFirstName', form.aFirstName);
    bodyFormData.append('aLastName', form.aLastName);
    bodyFormData.append('aDesignation', form.aDesignation);
    bodyFormData.append('aPhoneNumber', form.aPhoneNumber);
    bodyFormData.append('aEmail', form.aEmail);
    bodyFormData.append('bFirstName', form.bFirstName);
    bodyFormData.append('bLastName', form.bLastName);
    bodyFormData.append('bDesignation', form.bDesignation);
    bodyFormData.append('bPhoneNumber', form.bPhoneNumber);
    bodyFormData.append('bEmail', form.bEmail);
    bodyFormData.append('removeLogos', form.removeLogos);

    if(form.logo) {
        bodyFormData.append('customerLogo', form.logo);
    }


    return (dispatch) =>


    axios({
        method: "post",
        url: `/api/v1/auth/setup`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(response => {

        //   history.push('/auth/createsuperuser')

                return dispatch({
                    type: Action.SHOW_MESSAGE,
                    message: 'setup details successfully entered',
                    severity: 'success'
                });
        })
        .catch(error => {
            return dispatch({
                type: Action.SHOW_MESSAGE,
                message: error.message,
                severity: 'error'
            });
        });
 
}



export function createSuperUser(model, history)
{

    return (dispatch) =>
        axios
            .post('/api/v1/auth/createsuperuser', model)
            .then(res => {  


                history.push('/auth/login')

                return dispatch({
                    type: Action.SHOW_MESSAGE,
                    message: 'Super user successfully created',
                    severity: 'success'
                });

            }).catch(error => {

                return dispatch({
                    type: Action.SHOW_MESSAGE,
                    message: 'Failed to create Super user',
                    severity: 'error'
                });

        })
}

export function checkExistence(history)
{

    return (dispatch) =>
        axios
            .get('/api/v1/auth/checkexistence')
            .then(res => {  

                // dispatch({
                //     type: Actions.SETUP_DETAIL,
                //     payload: res.data.setup
                // });

                history.push('/auth/login')

                


            }).catch(error => {
         
                history.push('/auth/setup')
            
               

        })
}

export function getSetup()
{

    return (dispatch) =>
        axios
            .get('/api/v1/auth/getsetup')
            .then(res => {  

                return dispatch({
                    type: Actions.SETUP_DETAIL,
                    payload: res.data.setup
                });


            }).catch(error => {
         
                console.log(error)

        })
}




