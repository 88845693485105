import * as Actions from '../../actions/setup';

const initialState = {
    setup : null
};

const setup = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.SETUP_DETAIL:
        {
            return {
                ...initialState,
                setup: action.payload
            };
        
        }
        
        default:
        {
            return state;
        }
    }
};

export default setup;