import {combineReducers} from 'redux';
import auth from './auth';
import helper from './helper';
import setup from './setup/setup.reducer';
import user from './user/user.reducer';
import provisioning from './provisioning/provisioning.reducer';
import request from './request/request.reducer';

const createReducer = () =>
    combineReducers({
        auth,
        helper,
        setup,
        user,
        provisioning,
        request
    });

export default createReducer;