import axios from "axios";
import * as snackAction from 'store/actions/helper';
export const DELETE_USER = 'DELETE_USER';


export function deleteUser(userId)
{

    return (dispatch) =>
        axios
            .delete(`api/v1/auth/deleteuser/${userId}`)
            .then(res => {  

                dispatch(snackAction.showMessage('User Deleted successfully', "success"));

                dispatch({
                    type   : DELETE_USER,
                });


            }).catch(error => {

                dispatch(snackAction.showMessage(error.message, "success"));

                console.log(error)

        })
}