
import Link from '@mui/material/Link';
import { Typography } from "@material-ui/core";
import Login from "views/auth/Login.js";
import Setup from "views/auth/Setup";
import CreateSuperUser from "views/auth/CreateSuperUser";
import ForgotPassword from "views/auth/ForgotPassword";
import CreateNewPassword from "views/auth/CreateNewPassword";
import Dashboard from "views/superAdmin/Dashboard";
import ManageUsers from "views/superAdmin/ManageUsers";
import ProvisioningTemplate from "views/superAdmin/ProvisioningTemplate";
import ResourceRequest from "views/superAdmin/ResourceRequest";
import { Breadcrumbs } from "@material-ui/core";
import dashboard from "./assets/img/icons/svg/dashboard.svg";
import manage from "./assets/img/icons/svg/manage.svg";
import provision from "./assets/img/icons/svg/provision.svg";
import resource from "./assets/img/icons/svg/resource.svg";
import utilization from "./assets/img/icons/svg/utilization.svg";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import CreateUser from "views/common/ManageUsers/CreateUser";
import EditUser from 'views/common/ManageUsers/EditUser';
import Profile from 'views/common/Profile';
import ViewRequest from 'views/common/ViewRequest';
import BulkApprover from 'views/approver/BulkApprover';



//requestor routes
import RequestorUtilization from 'views/requestor/Utilization';
import RequestorResourceRequest from 'views/requestor/ResourceRequest';
import RequestorDashboard from 'views/requestor/Dashboard';

//approver routes
import ApproverResourceRequest from 'views/approver/ResourceRequest';
import ApproverDashboard from 'views/approver/Dashboard';
import ApproverUtilization from 'views/approver/Utilization';

//admin routes
import AdminUtilization  from 'views/admin/Utilization';
import AdminResourceRequest from 'views/admin/ResourceRequest';
import AdminManageUsers from 'views/admin/ManageUsers';
import AdminDashboard from 'views/admin/Dashboard';

var routes = [
  {
    path: "/login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/setup",
    name: "Welcome to AEP 2.0!",
    component: Setup,
    layout: "/auth",
  },
  {
    path: "/createsuperuser",
    name: "",
    component: CreateSuperUser,
    layout: "/auth",
  },
  {
    path: "/forgotpassword",
    name: "",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/createnewpassword",
    name: "",
    component: CreateNewPassword,
    layout: "/auth",
  },
  {
    path: "/index",
    name: "Dashboard",
    header: "super user dashboard",
    icon: dashboard,
    iconColor: "Primary",
    component: Dashboard,
    layout: "/superadmin",
  },
  {
    path: "/index",
    name: "Dashboard",
    header: "Approver dashboard",
    icon: dashboard,
    iconColor: "Primary",
    component: ApproverDashboard,
    layout: "/approver",
  },
  {
    path: "/index",
    name: "Dashboard",
    header: "Requestor dashboard",
    icon: dashboard,
    iconColor: "Primary",
    component: RequestorDashboard,
    layout: "/requestor",
  },
  {
    path: "/manageUsers",
    name: "Manage Users",
    header: "Manage Users",
    icon: manage,
    iconColor: "Primary",
    component: ManageUsers,
    layout: "/superadmin",
  },
  {
    path: "/index",
    name: "Dashboard",
    header: "Administrator dashboard",
    icon: dashboard,
    iconColor: "Primary",
    component: AdminDashboard,
    layout: "/admin",
  },
  {
    path: "/manageUsers",
    name: "Manage Users",
    header: "Manage Users",
    icon: manage,
    iconColor: "Primary",
    component: AdminManageUsers,
    layout: "/admin",
  },
  {
    path: "/createuser",
    name: "Manage Users",
    header: <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" color="white">
    <Link underline="hover" color="inherit" href="/superadmin/manageusers">
      Manage Users
    </Link>
    
    <Typography color="text.primary">Create User</Typography>
  </Breadcrumbs>,
    icon: manage,
    iconColor: "Primary",
    component: CreateUser,
    layout: "/superadmin",
    hide: true
  },

  {
    path: "/createuser",
    name: "Manage Users",
    header: <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" color="white">
    <Link underline="hover" color="inherit" href="/admin/manageusers">
      Manage Users
    </Link>
    
    <Typography color="text.primary">Create User</Typography>
  </Breadcrumbs>,
    icon: manage,
    iconColor: "Primary",
    component: CreateUser,
    layout: "/admin",
    hide: true
  },
  {
    path: "/edituser/:userId",
    name: "Manage Users",
    header: <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" color="white">
    <Link underline="hover" color="inherit" href="/superadmin/manageusers">
      Manage Users
    </Link>
    
    <Typography color="text.primary">Edit User</Typography>
  </Breadcrumbs>,
    icon: manage,
    iconColor: "Primary",
    component: EditUser,
    layout: "/superadmin",
    hide: true
  },
  {
    path: "/edituser/:userId",
    name: "Manage Users",
    header: <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" color="white">
    <Link underline="hover" color="inherit" href="/admin/manageusers">
      Manage Users
    </Link>
    
    <Typography color="text.primary">Edit User</Typography>
  </Breadcrumbs>,
    icon: manage,
    iconColor: "Primary",
    component: EditUser,
    layout: "/admin",
    hide: true
  },
  {
    path: "/provisioning",
    name: "Provisioning Template",
    header: "super user dashboard",
    icon: provision,
    iconColor: "Primary",
    component: ProvisioningTemplate,
    layout: "/superadmin",
  },
  {
    path: "/profile",
    name: "Profile",
    header: "User Profile",
    icon: provision,
    hide: true,
    iconColor: "Primary",
    component: Profile,
    layout: "/superadmin",
  },
  {
    path: "/profile",
    name: "Profile",
    header: "User Profile",
    icon: provision,
    hide: true,
    iconColor: "Primary",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profile",
    header: "User Profile",
    icon: provision,
    hide: true,
    iconColor: "Primary",
    component: Profile,
    layout: "/requestor",
  },
  {
    path: "/profile",
    name: "Profile",
    header: "User Profile",
    icon: provision,
    hide: true,
    iconColor: "Primary",
    component: Profile,
    layout: "/approver",
  },
  {
    path: "/viewrequest/:requestId",
    name: "viewRequest",
    header: <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" color="white">
    <Link underline="hover" color="inherit" href="/superadmin/resourcerequests">
      Resources
    </Link>
    
    <Typography color="text.primary">View Request</Typography>
  </Breadcrumbs>,
    icon: provision,
    hide: true,
    iconColor: "Primary",
    component: ViewRequest,
    layout: "/superadmin",
  },
  {
    path: "/viewrequest/:requestId",
    name: "viewRequest",
    header: <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" color="white">
    <Link underline="hover" color="inherit" href="/admin/resourcerequests">
      Resources
    </Link>
    
    <Typography color="text.primary">View Request</Typography>
  </Breadcrumbs>,
    icon: provision,
    hide: true,
    iconColor: "Primary",
    component: ViewRequest,
    layout: "/admin",
  },
  {
    path: "/viewrequest/:requestId",
    name: "viewRequest",
    header: <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" color="white">
    <Link underline="hover" color="inherit" href="/approver/resourcerequests">
      Resources
    </Link>
    
    <Typography color="text.primary">View Request</Typography>
  </Breadcrumbs>,
    icon: provision,
    hide: true,
    iconColor: "Primary",
    component: ViewRequest,
    layout: "/approver",
  },
  {
    path: "/viewrequest/:requestId",
    name: "viewRequest",
    header: "View Request",
    icon: provision,
    hide: true,
    iconColor: "Primary",
    component: ViewRequest,
    layout: "/requestor",
  },
  {
    path: "/createrequest",
    name: "Create Request",
    header: "Create Request",
    icon: provision,
    hide: true,
    iconColor: "Primary",
    component: ViewRequest,
    layout: "/requestor",
  },
  {
    path: "/resourcerequests",
    name: "Resource Requests",
    header: "Resources",
    icon: resource,
    iconColor: "Primary",
    component: ResourceRequest,
    layout: "/superadmin",
  },
  {
    path: "/resourcerequests",
    name: "Resource Requests",
    header: "Resources",
    icon: resource,
    iconColor: "Primary",
    component: AdminResourceRequest,
    layout: "/admin",
  },
  {
    path: "/resourcerequests",
    name: "Resource Requests",
    header: "Resources",
    icon: resource,
    iconColor: "Primary",
    component: ApproverResourceRequest,
    layout: "/approver",
  },
  {
    path: "/bulkapproval",
    name: "Bulk Approval",
    header: "Resources",
    icon: resource,
    iconColor: "Primary",
    component: BulkApprover,
    layout: "/approver",
  },
  {
    path: "/resourcerequests",
    name: "Resource Requests",
    header: "Resources",
    icon: resource,
    iconColor: "Primary",
    component: RequestorResourceRequest,
    layout: "/requestor",
  },
  {
    path: "/utilization",
    name: "Utilization",
    header: "Approver dashboard",
    icon: utilization,
    iconColor: "Primary",
    component: ApproverUtilization,
    layout: "/approver",
  },
  {
    path: "/utilization",
    name: "Utilization",
    header: "admin dashboard",
    icon: utilization,
    iconColor: "Primary",
    component: AdminUtilization,
    layout: "/admin",
  },
  {
    path: "/utilization",
    name: "Utilization",
    header: "requestor dashboard",
    icon: utilization,
    iconColor: "Primary",
    component: RequestorUtilization,
    layout: "/requestor",
  },
];
export default routes;
