import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import EventNote from "@material-ui/icons/EventNote";
import LiveHelp from "@material-ui/icons/LiveHelp";
import Person from "@material-ui/icons/Person";
import Settings from "@material-ui/icons/Settings";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from "react-router-dom";
import { useSelector , useDispatch } from 'react-redux';
// core components
import componentStyles from "assets/theme/components/navbar-dropdown.js";
import * as Action from 'store/actions/auth';
import profile from 'assets/img/icons/common/profile.svg'
const useStyles = makeStyles(componentStyles);

export default function NavbarDropdown(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const dispatch = useDispatch();
  
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const user = useSelector(({auth}) => auth.user.data);  
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      style={{
        marginTop: "3rem",
      }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        
        onClick={handleMenuClose}
      >
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
        />
        <Link to={`/${user.role.toLowerCase()}/profile`} style={{color: 'black', textDecoration: 'none'}}>
        <Typography style={{color: '#57737A'}}>Profile</Typography>
        </Link>
      </Box>
      
      <Divider   />
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={() => {       
          dispatch(Action.logoutUser(props)); 
          handleMenuClose();
      }}
        
      >
        <Box
         
          width="1.25rem!important"
          height="1.25rem!important"
          
        />
        <Typography style={{color: '#57737A'}}>Logout</Typography>
      </Box>
    </Menu>
  );

  if(!user) {
    return null
  }

  return (
    <>
      <Button
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        classes={{
          label: classes.buttonLabel,
          root: classes.buttonRoot,
        }}
      >

      <Avatar
          alt="profile icon"
          src={profile}
          color="primary"
          style={{
            background: '#78d75'
          }}
          classes={{
            root: classes.avatarRoot,
          }}
        />
       
        <Hidden smDown>{user.firstName + ' ' + user.lastName} <ArrowDropDownIcon /></Hidden>
      </Button>
      {renderMenu}
    </>
  );
}
