import * as Action from './login.actions';
import axios from 'axios';
import * as Actions from '../../actions/user';
import browserHistory from 'browserHistory';
import createHistory from 'history/createBrowserHistory';
import { customHistory } from 'index';
import * as snackAction from 'store/actions/helper';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';

export function setUserData(user, setup)
{
    
    return (dispatch) => {

        dispatch({
            type   : Action.SET_USER_DATA,
            payload: user
        });

        dispatch({
            type: Action.SETUP_DETAIL,
            payload: setup
        });

        
    }
}

export function logoutUser(history)
{

    return (dispatch) => {

        localStorage.removeItem('jwt_access_token');
        delete axios.defaults.headers.common['Authorization'];


        customHistory.push('/auth/login');

        

        dispatch({
            type: Action.LOGGED_OUT
        })
    }
}

export function updateProfile(model)
{

    return (dispatch) =>
        axios
            .put(`api/v1/auth/updateprofile`, model)
            .then(res => {  

                dispatch({
                    type   : Action.SET_USER_DATA,
                    payload: res.data.user
                });

                dispatch(snackAction.showMessage('Porfile updated successfully', "success"));

            }).catch(error => {

                dispatch(snackAction.showMessage(error.response.data.error, "error"));

                console.log(error)

        })
}

export function updateOtherUsers(model)
{

    return (dispatch) =>
        axios
            .put(`api/v1/auth/updateotherusers`, model)
            .then(res => {  

                dispatch({
                    type   : Actions.GET_USER,
                    payload: res.data.user
                });

                dispatch(snackAction.showMessage('User updated successfully', "success"));

            }).catch(error => {

                dispatch(snackAction.showMessage(error.response.data.error, "error"));

        })
}

export function changePassword(model)
{

    return (dispatch) =>
        axios
            .put(`api/v1/auth/changepassword`, model)
            .then(res => {  

                dispatch(snackAction.showMessage('Password changed successfully', "success"));

                dispatch({
                    type   : PASSWORD_CHANGED
                });

            }).catch(error => {

                dispatch(snackAction.showMessage(error.response.data.error, "error"));

        })
}