import axios from 'axios';
import * as snackAction from 'store/actions/helper';
import * as Action from '../helper/index';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SETUP_DETAIL = 'SETUP_DETAIL';
export const SET_USER_DATA = 'SET_USER_DATA';
export const LOGGED_OUT = 'LOGGED_OUT';

export function login(model, history)
{

    return (dispatch) =>
        axios
            .post('/api/v1/auth/login', model)
            .then(res => {  

                localStorage.setItem('jwt_access_token', res.data.token);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;

                history.push('/superadmin/index')

                dispatch({
                    type: Action.SHOW_MESSAGE,
                    message: 'Seccessfully logged in',
                    severity: 'success'
                })

                dispatch({
                    type: SET_USER_DATA,
                    payload: res.data.user
                })
                

                return dispatch({
                            type: SETUP_DETAIL,
                            payload: res.data.setup
                });

            }).catch(error => {

                dispatch(snackAction.showMessage(error.response.data.error, "error"));

                return dispatch({
                    type   : LOGIN_ERROR,
                    payload: error.response.data.error
                });

        })
}
