import axios from "axios";

import * as Action from './getUserRequests.actions';


export function getAllRequests(page)
{

    return (dispatch) =>
        axios
            .get(`api/v1/request/getallrequests?page=${page}`)
            .then(res => {  

                dispatch({
                    type   : Action.GET_USER_REQUESTS,
                    payload: res.data.requests,
                    pageNumbers: res.data.pageNumbers,
                    total: res.data.total,
                    accepted: res.data.accepted,
                    pending: res.data.pending
                });

            }).catch(error => {

                console.log(error)

        })
}