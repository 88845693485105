import axios from 'axios';
import * as snackAction from 'store/actions/helper';
import * as Action from '../helper/index';
import * as Actions from './user.actions';
export const SENT_OTP = 'SEND_OTP';

const tok = localStorage.getItem('jwt_access_token');
axios.defaults.headers.common['Authorization'] = 'Bearer ' + tok;

export function sendOtp(model, history)
{


    return (dispatch) =>
        axios
            .post('/api/v1/auth/forgotpassword', model)
            .then(res => {  

                dispatch(snackAction.showMessage('OTP sent successfully', "success"));


                return dispatch({
                            type: SENT_OTP
                });

            }).catch(error => {

                dispatch(snackAction.showMessage(error.response.data.error, "error"));

        })
}

export function verifyOtp(model, history)
{


    return (dispatch) =>
        axios
            .put(`api/v1/auth/resetpassword/${model.email}/${model.otp}`)
            .then(res => {  

                localStorage.setItem('jwt_access_token', res.data.token);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;



                history.push('/auth/createnewpassword')

                dispatch(snackAction.showMessage("OTP verified successfully", "success"));

            }).catch(error => {

                dispatch(snackAction.showMessage('Incorrect OTP entered', "error"));
                

        })
}

export function updatePassword(model, history)
{


    return (dispatch) =>
        axios
            .put(`api/v1/auth/updatepassword`, model)
            .then(res => {  

                

                dispatch(Actions.logoutUser());

                return dispatch({
                    type: Action.SHOW_MESSAGE,
                    message: 'Password updated successfully',
                    severity: 'success'
                });

            }).catch(error => {

                dispatch(snackAction.showMessage(error.response.data.error, "error"));

        })
}
