import axios from "axios";

import * as Action from './getUserRequests.actions';


export function getPendingRequests()
{

    return (dispatch) =>
        axios
            .get(`api/v1/request/getpendingrequests`)
            .then(res => {  

                dispatch({
                    type   : Action.GET_USER_REQUESTS,
                    payload: res.data.requests
                });

            }).catch(error => {

                console.log(error)

        })
}