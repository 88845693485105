import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Provider from "react-redux/es/components/Provider";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "assets/theme/theme.js";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/dashboard-react.scss";
import store from "./store";
import axios from "axios";
import AuthLayout from "views/auth/layout/Auth.js";
import SuperAdmin from "views/superAdmin/layout/SuperAdmin.js";
import Admin from "views/admin/layout/Admin";
import Approver from "views/approver/layout/Approver";
import Requestor from "views/requestor/layout/Requestor";
import Authorization from "views/common/Authorization/Authorization";
import createBrowserHistory from "history/createBrowserHistory";
import { Router } from "react-router-dom";
import { withRouter } from "react-router-dom";

// dotenv.config();

export const customHistory = createBrowserHistory();

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <Authorization>
        <Router history={customHistory}>
          <Switch>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route
              path="/superadmin"
              render={(props) => <SuperAdmin {...props} />}
            />
            <Route path="/admin" render={(props) => <Admin {...props} />} />
            <Route
              path="/approver"
              render={(props) => <Approver {...props} />}
            />
            <Route
              path="/requestor"
              render={(props) => <Requestor {...props} />}
            />
            <Redirect from="/" to="/requestor" />
          </Switch>
        </Router>
      </Authorization>
    </Provider>
  </ThemeProvider>,
  document.querySelector("#root")
);
