import axios from "axios";
import * as snackAction from 'store/actions/helper';
export const DECLINE_REQUEST = 'DECLINE_REQUEST';


export function rejectRequest(requestId, rejectReason, props)
{

    return (dispatch) =>
        axios
            .post(`api/v1/request/declinerequest`, {requestId, rejectReason})
            .then(res => {  
                dispatch(snackAction.showMessage('Request declined successfully', "success"));
                dispatch({
                    type   : DECLINE_REQUEST
                });
                props.history.push('/approver/resourcerequests')

            }).catch(error => {
                dispatch(snackAction.showMessage(error.message, "error"));
                console.log(error)

        })
}