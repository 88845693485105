import axios from "axios";

import * as Action from './getUsers.actions';


export function searchUsers(query)
{

    return (dispatch) =>
        axios
            .get(`api/v1/auth/users?search=${query}`)
            .then(res => {  

                dispatch({
                    type   : Action.GET_USERS,
                    payload: res.data.users
                });

            }).catch(error => {

                console.log(error)

        })
}