import * as Actions from '../../actions/provisioning';

const initialState = {
    provisioning : null,
    exist: null
};

const provisioning = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_PROVISIONING:
        {
            return {
                ...initialState,
                provisioning: action.payload
            };
        
        }
        case Actions.NO_PROVISIONING:
        {
            return {
                ...initialState,
                exist: false
            };
        
        }
        
        default:
        {
            return state;
        }
    }
};

export default provisioning;