import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// @material-ui/icons components
import Search from "@material-ui/icons/Search";
import Sidebar from "views/common/Sidebar.js";
// core components
import Navbar from "views/common/Navbar/Navbar.js";
import NavbarDropdown from "views/common/Navbar/NavbarDropdown.js";
import { useSelector, useDispatch } from "react-redux";
import routes from "routes.js";
import * as Action from "store/actions/helper";
import componentStyles from "assets/theme/layouts/admin.js";
import Alert from "@mui/material/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Snackbar } from "@material-ui/core";

const useStyles = makeStyles(componentStyles);

const Requestor = (props) => {
  const classes = useStyles();
  const location = useLocation();

  var APIUrl = process.env.REACT_APP_API_BASE_URL + "/uploads/";

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/requestor") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const user = useSelector(({ auth }) => auth.user.data);
  const setup = useSelector(({ setup }) => setup.setup);

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
    
      const fullpath = routes[i].layout + routes[i].path;

      if (fullpath.includes(location.pathname.slice(0, 16))) {
        return routes[i].header;
      }
    }
    return "";
  };

  const dispatch = useDispatch();
  const checkMessage = useSelector(({ helper }) => helper.message.show);
  const checkMessageText = useSelector(({ helper }) => helper.message.message);
  const checkMessageSeverity = useSelector(
    ({ helper }) => helper.message.severity
  );

  function hideMessage() {
    dispatch({
      type: Action.HIDE_MESSAGE,
    });
  }

  if (!user || !setup) {
    return null;
  }

  if (!(user.role == "requestor")) {
    props.history.push(`/${user.role}/index`);
  }

  return (
    <>
      <>
        <Snackbar
          open={checkMessage}
          autoHideDuration={3000}
          onClose={() => {
            hideMessage();
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert
            sx={{ width: "100%" }}
            variant="filled"
            severity={checkMessageSeverity}
          >
            {checkMessageText}
          </Alert>
        </Snackbar>
        <Sidebar
          routes={routes}
          logo={{
            innerLink: "/superadmin/index",
            imgSrc: setup.customerLogo
              ? `${APIUrl}/${setup.customerLogo}`
              : require("assets/img/brand/Datamato_Logo.png"),
            imgAlt: "...",
          }}
          dropdown={<NavbarDropdown />}
          input={
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-search-responsive">
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-search-responsive"
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <Box
                      component={Search}
                      width="1.25rem!important"
                      height="1.25rem!important"
                    />
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          }
        />
        <Box position="relative" className={classes.mainContent}>
          <Navbar brandText={getBrandText(location.pathname)} />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/requestor/index" />
          </Switch>
          <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
          ></Container>
        </Box>
      </>
    </>
  );
};

export default Requestor;
