import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Snackbar } from "@material-ui/core";

// core components
import componentStyles from "assets/theme/components/auth-header.js";

import { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import * as Action from '../../../store/actions/helper';

const useStyles = makeStyles(componentStyles);

const AuthHeader = () => {
  const classes = useStyles();
  const theme = useTheme();  
  const [showSnack, setShowSnack] = useState(true);
  const dispatch = useDispatch();
  const checkMessage = useSelector(({helper}) => helper.message.show);
  const checkMessageText = useSelector(({helper}) => helper.message.message);
  const checkMessageSeverity = useSelector(({helper}) => helper.message.severity);

  function hideMessage()
  {

    dispatch({
      type: Action.HIDE_MESSAGE
  })

  }

  return (
    <>
      <Box
        className={classes.header}
        position="relative"
        paddingTop="8rem"
        paddingBottom="8rem"
      >

        <Snackbar open={checkMessage} autoHideDuration={6000} onClose={() => {hideMessage()}} anchorOrigin={{
              vertical: "top",
              horizontal: "center"
          }}>
            
            <Alert variant="filled" severity={checkMessageSeverity} >
              {checkMessageText}
            </Alert>
            
        </Snackbar>
        <Container maxWidth="xl">
          <Box marginBottom="6rem" textAlign="center">
            <Box
              component={Grid}
              container
              justifyContent="center"
              color={theme.palette.white.main}
            >
              <Grid item lg={5} md={6} xs={12}>
                <h1>Welcome!</h1>
                <Box
                  component="p"
                  color={theme.palette.gray[400]}
                  lineHeight="1.7"
                  fontSize="1rem"
                >
                  Log into your account to start using our framework
                </Box>
              </Grid>
            </Box>
          </Box>
        </Container>
        <Box
          position="absolute"
          zIndex="100"
          height="70px"
          top="auto"
          bottom="0"
          pointerEvents="none"
          left="0"
          right="0"
          width="100%"
          overflow="hidden"
          transform="translateZ(0)"
        >
          <Box
            bottom="0"
            position="absolute"
            pointerEvents="none"
            component="svg"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <Box
              component="polygon"
              fill={theme.palette.dark.main}
              points="2560 0 2560 100 0 100"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AuthHeader;
