import React, {useEffect}  from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { InputAdornment } from "@material-ui/core";
import FilledInput from "@material-ui/core/FilledInput";
import Button from "@material-ui/core/Button";
import CloseIcon from '@mui/icons-material/Close';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from "react-router-dom";
// @material-ui/icons components
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import CardActions from "@material-ui/core/CardActions";
import MoreVert from "@material-ui/icons/MoreVert";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch, useSelector } from 'react-redux';
// core components
import HeaderClasses from "assets/theme/components/header.js";
import * as Actions from 'store/actions/user';
import componentStyles from "assets/theme/views/admin/dashboard.js";
import tableStyles from "assets/theme/views/admin/tables.js";
import { Divider } from "@material-ui/core";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import * as snackAction from 'store/actions/helper';
import { Helmet } from 'react-helmet'
  
  const useStyles = makeStyles(componentStyles);
  const useTableStyles = makeStyles(tableStyles);
  
  const useHeaderStyles = makeStyles(HeaderClasses);



  function ManageUsers() {

    const classes = useStyles();
    const headerClasses = useHeaderStyles();
    const tableClasses = useTableStyles();
    const theme = useTheme();

    const [anchorEl1, setAnchorEl1] = React.useState(null);

    const [userId, setUserId] = React.useState(null);

    

    const handleClose = () => {
      setAnchorEl1(null);
    
    };


    const [activeNav, setActiveNav] = React.useState(1);

    const [open, setOpen] = React.useState(false);
    

    const users = useSelector(({user}) => user.users);
    const user = useSelector(({auth}) => auth.user.data); 
    const setup = useSelector(({setup}) => setup.setup);
    const pageNumbers = useSelector(({user}) => user.pageNumbers);

    const dispatch = useDispatch();

    const handleDelete = (userId) => {
      setOpen(true)
      setUserId(userId)
    }
  
    const handleCloseDialog = () => {
      setOpen(false);
    };

    const handlePagination = (event) => {
      dispatch(Actions.getUsers(event.target.textContent));
    };

    const handleSearch = (event) => {

      dispatch(Actions.searchUsers(event.target.value));
    }

    const handleActualDelete = () => {
      setOpen(false);
      dispatch(Actions.deleteUser(userId));
      dispatch(Actions.getUsers(1));
    }

    useEffect(() => {
      dispatch(Actions.getUsers(1));
  }, [dispatch]);

  if(!users) {
    return  null
  }

    return (
        <>

        <Helmet>
        <title>{`Manage Users | ${setup.organizationName}`}</title>
        </Helmet>

        <Box minHeight="1rem" >
          
        </Box>
      
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="6rem"
        classes={{ root: classes.containerRoot }}
      >

        <Card
              classes={{
                root: classes.cardRoot,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        User Details
                      </Box>
                    </Grid>

                    <Paper
                      component="form"
                      style={{                    
                        
                        boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.02), 0px 1px 3px rgba(50, 50, 93, 0.15)"
                      }}
                      sx={{  display: 'flex', alignItems: 'center', width: 500.  }}
                    >
                      {/* <IconButton sx={{ p: '10px' }} aria-label="menu">
                      
                      </IconButton> */}
                      <FilledInput

                      
                      startAdornment={
                        <InputAdornment position="start">
                         <SearchIcon />
                        </InputAdornment>
                      }
                       
                      onChange={handleSearch}
                        sx={{ ml: 1, flex: 1,  }}
                        placeholder="Search by Employee ID/Name/Email"
                        inputProps={{ 'aria-label': 'Search by Employee ID/Name/Email' }}
                      />
                      
                    </Paper>
                    
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Link to={`/${user.role.toLowerCase()}/createuser`} style={{color: '#57737A', textDecoration: 'none'}}>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                        >
                          Create User
                        </Button>
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                }
                style={{
                  height: '70px'
                }}
              ></CardHeader>
              <TableContainer>
                <Box
                  component={Table}
                  alignItems="center"
                  marginBottom="0!important"
                >
                  <TableHead>
                    <TableRow>
                    <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        User Name
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Employee ID
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Created On
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Role
                      </TableCell>

                      <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  ></TableCell>
                     
                    </TableRow>
                  </TableHead>
                  <TableBody>

                  {users.map((userData, i) => 

                    <IsolatedMenu  userData={userData} user={user} classes={classes} tableClasses={tableClasses} theme={theme} handleDelete={handleDelete} setOpen={setOpen}/>
                  
                  )}
                    
                    
                    
                  </TableBody>
                </Box>
              </TableContainer>

              <Box
            classes={{ root: classes.cardActionsRoot }}
            component={CardActions}
            justifyContent="flex-end"
          >
            <Pagination hideNextButton hidePrevButton onClick={handlePagination} count={pageNumbers} color="primary" variant="outlined" />
          </Box>
            </Card>

            

            <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        style={{
          boxShadow: "none",
          
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle  style={{background: '#e30c57', display: 'flex',
    justifyContent: 'space-between'}} color="white" id="alert-dialog-title">
            Delete User <CloseIcon />
        </DialogTitle>
        <DialogContent>
          <DialogContentText paddingTop='30px' id="alert-dialog-description">
            Are you sure you want to delete this user? This process can not be reversed
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{paddingLeft:"70px", paddingRight:"20px", paddingBottom: "25px"}}>
          <Button fullWidth variant="outlined"
                          color="primary" onClick={handleCloseDialog}>Cancel</Button>
          <Button fullWidth variant="contained"
                          color="primary" onClick={handleActualDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </Container>



        </>

    );

  }

  export default ManageUsers;


  const IsolatedMenu = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    return(


      <TableRow>
      <TableCell
          classes={{
            root:
              props.classes.tableCellRoot +
              " " +
              props.classes.tableCellRootBodyHead,
          }}
          component="th"
          variant="head"
          scope="row"
        >
          {`${props.userData.firstName} ${props.userData.lastName}`}
        </TableCell>
        <TableCell classes={{ root: props.classes.tableCellRoot }}>
        {props.userData.employeeId}
        </TableCell>
        <TableCell
          classes={{
            root:
            props.classes.tableCellRoot +
              " " +
              props.classes.tableCellRootBodyHead,
          }}
          component="th"
          variant="head"
          scope="row"
        >
          {props.userData.email}
        </TableCell>
        <TableCell classes={{ root: props.classes.tableCellRoot }}>
        {new Date(props.userData.createdAt).toLocaleDateString()}
        </TableCell>
        <TableCell classes={{ root: props.classes.tableCellRoot }}>
        <Box paddingTop=".35rem" paddingBottom=".35rem">
        <Box
          marginRight="10px"
          component="i"
          width=".375rem"
          height=".375rem"
          borderRadius="50%"
          display="inline-block"
          className={
            `${props.tableClasses.verticalAlignMiddle} + " " + ${ props.userData.role == 'requestor' ? props.tableClasses.bgPending: props.userData.role == 'superAdmin' ? props.tableClasses.bgSuccess: props.userData.role == 'admin' ? props.tableClasses.bgSuccess:  props.tableClasses.bgPrimary}`
          }
        ></Box>
          {props.userData.role}
          </Box>
        </TableCell>
        
        <TableCell
      classes={{ root: props.classes.tableCellRoot }}
      align="right"
    >
      <Box
        aria-controls="simple-menu-1"
        aria-haspopup="true"
        style={{border: 'none' , boxShadow: 'none'}}
        onClick={event => setAnchorEl(event.currentTarget)}
        size="small"
        component={Button}
        width="2rem!important"
        height="2rem!important"
        minWidth="2rem!important"
        minHeight="2rem!important"
      >
        <Box
          component={MoreVert}
          width="1.25rem!important"
          height="1.25rem!important"
          position="relative"
          top="2px"
          color={props.theme.palette.gray[500]}
        />
      </Box>
      <Menu
        id="simple-menu-1"
        anchorEl={anchorEl}
    keepMounted
    open={open}
    onClose={() => setAnchorEl(null)}
      >
        
         <Link onClick={() => window.location.href=`/${props.user.role.toLowerCase()}/edituser/${props.userData._id}`} to={`/${props.user.role.toLowerCase()}/edituser/${props.userData._id}`} style={{color: '#57737A', textDecoration: 'none'}}>
          <MenuItem onClick={() => {
        setAnchorEl(null);
    }}>Edit</MenuItem>
        </Link>
        <Divider />
        <MenuItem onClick={() => {
        setAnchorEl(null);
        props.handleDelete(props.userData._id)
    }} value={props.userData._id} id='deleteUser'>Delete</MenuItem>
        
      </Menu>
    </TableCell>
      </TableRow>

    )

  }