import React,  {useEffect} from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons components
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import { useDispatch, useSelector } from 'react-redux';
// core components
import SecondHeader from "views/common/Headers/SecondHeader";
import { Link } from "react-router-dom";
import * as requestAction from 'store/actions/request';


import componentStyles from "assets/theme/views/admin/dashboard.js";
import tableStyles from "assets/theme/views/admin/tables.js";
import { Helmet } from 'react-helmet'

const useStyles = makeStyles(componentStyles);
const useTableStyles = makeStyles(tableStyles);

function RequestorDashboard() {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const theme = useTheme();
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");

 

  const user = useSelector(({auth}) => auth.user.data);
  const setup = useSelector(({setup}) => setup.setup);
  const requests = useSelector(({request}) => request.requests);
  const total = useSelector(({request}) => request.total);
  const accepted = useSelector(({request}) => request.accepted);
  const dispatch = useDispatch();


  const toggleNavs = (index) => {
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    dispatch(requestAction.getAllRequests(1));
  }, [dispatch]);

  if(!requests) {
    return  null
  }

  return (
    <>
        <Helmet>
          <title>{`Dashboard | ${setup.organizationName}`}</title>
        </Helmet>
      <SecondHeader requests={total} approved={accepted} />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        
        <Grid container component={Box} marginTop="8rem">
          <Grid
            item
            
            xl={6}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot }}
          >

<Card
              classes={{
                root: classes.cardRoot,
              }}
            >
              <CardHeader style={{borderBottom: "none"}}
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        style={{
                          color:"#E15638"
                        }}
                        marginBottom="0!important"
                      >
                        Resource Requests
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Typography>
                          Total Requests {total}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <TableContainer >
                <Box
                
                  component={Table}
                  alignItems="center"
                  marginBottom="0!important"
                >
                  
                  <TableBody >
                  {requests.map((request, i) =>


                    <TableRow >
                    <TableCell
                    style={{borderBottom: "none"}}
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootBodyHead,
                      }}
                      component="th"
                      variant="head"
                      scope="row"
                    >
                      {request.rfcNo}
                    </TableCell>
                    <TableCell align="right" style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem">
                    <Box
                      marginRight="10px"
                      component="i"
                      width=".375rem"
                      height=".375rem"
                      borderRadius="50%"
                      display="inline-block"
                      className={
                        `${tableClasses.verticalAlignMiddle} + " " + ${ request.status == 'pending' || request.status == 'approved' ? tableClasses.bgPending: request.status == 'rejected' || request.status == 'failed' ? tableClasses.bgError:  tableClasses.bgSuccess}`
                      }
                    ></Box>
                      {request.status == 'approved' ? 'Executing' : capitalizeFirstLetter(request.status)}
                      </Box>
                    </TableCell>


                    </TableRow>


                    )}
                   

                    <TableRow>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        
                      </TableCell>
                     
                      <TableCell align="right" classes={{ root: classes.tableCellRoot }}>
                      <Link style={{color: '#57737A', textDecoration: 'none'}} to={`/${user.role}/resourcerequests`}> <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                        flexWrap: 'wrap',
                    }}>see all <ChevronRightIcon /> </div></Link>
                      </TableCell>
                      
                    </TableRow>
                    
                    
                  </TableBody>
                </Box>
              </TableContainer>
            </Card>
            
          </Grid>

          <Grid
            item
            
            xl={6}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot }}
          >

<Card classes={{ root: classes.cardRoot }}>
              <CardHeader
              style={{borderBottom: "none"}}
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        style={{
                          color:"#E15638",
                          
                        }}
                        marginBottom="0!important"
                      >
                        Provisioned Environments
                      </Box>
                    </Grid>
                    
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <TableContainer>
                <Box
                  component={Table}
                  alignItems="center"
                  marginBottom="0!important"
                >
                  
                  <TableBody>
                    <TableRow>
                      <TableCell
                      style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        UAT Setup
                      </TableCell>
                      
                      <TableCell style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }} align="center">                      
                          {accepted}                      
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        Pre Production Setup
                      </TableCell>
                      
                      <TableCell style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }} align="center">                      
                          0                      
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        Primary Datacenter
                      </TableCell>
                      
                      <TableCell style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }} align="center">                      
                          0                      
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        Recovery Datacenter
                      </TableCell>
                      
                      <TableCell style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }} align="center">                      
                          0                      
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead +
                            " " +
                            classes.borderBottomUnset,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        NDR Datacenter
                      </TableCell>
                      
                      <TableCell style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }} align="center">                      
                          0                      
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Box>
              </TableContainer>
            </Card>

          </Grid>
          
        </Grid>

        <Grid container component={Box} marginTop="1rem">
          <Grid
            item
            
            xl={6}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot }}
          >


          </Grid>

          <Grid
            item
            
            xl={6}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot }}
          >

       

          </Grid>

        </Grid>
      </Container>
    </>
  );
}

export default RequestorDashboard;
