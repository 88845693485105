import axios from "axios";

export const GET_USER_REQUESTS = 'GET_USER_REQUESTS';


export function getUserRequests()
{

    return (dispatch) =>
        axios
            .get(`api/v1/request/getuserrequests`)
            .then(res => {  

                dispatch({
                    type   : GET_USER_REQUESTS,
                    payload: res.data.requests
                });

            }).catch(error => {

                console.log(error)

        })
}