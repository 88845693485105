import axios from "axios";
import * as snackAction from 'store/actions/helper';
export const CREATE_PROVISIONING = 'CREATE_PROVISIONING';


export function createProvisioning(model)
{

    return (dispatch) =>
        axios
            .post(`api/v1/provisioning/createprovisioning`, model)
            .then(res => {  
                dispatch(snackAction.showMessage('Provisioning created successfully', "success"));
                dispatch({
                    type   : CREATE_PROVISIONING
                });

            }).catch(error => {

                dispatch(snackAction.showMessage(error.response.data.error, "error"));

        })
}