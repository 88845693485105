import React, {useEffect} from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Formsy from 'formsy-react';
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import CardActions from "@material-ui/core/CardActions";
import Pagination from "@material-ui/lab/Pagination";
import { TextField } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { useSelector, useDispatch } from 'react-redux';
// core components
import * as Action from 'store/actions/auth';
import HeaderClasses from "assets/theme/components/header.js";
import useForm from "utils/useForm";
import componentStyles from "assets/theme/views/admin/dashboard.js";
import tableStyles from "assets/theme/views/admin/tables.js";
import { Helmet } from 'react-helmet'
  
  const useStyles = makeStyles(componentStyles);
  const useTableStyles = makeStyles(tableStyles);
  
  const useHeaderStyles = makeStyles(HeaderClasses);

 

  function Profile() {

    const classes = useStyles();
  
    const headerClasses = useHeaderStyles();
    const tableClasses = useTableStyles();
    const theme = useTheme();
    const [activeNav, setActiveNav] = React.useState(1);
    const [confirmErr, setConfirmErr] = React.useState('');
    const [disabledInput, setDisabledInput] = React.useState(true);
    const user = useSelector(({auth}) => auth.user.data); 
    const setup = useSelector(({setup}) => setup.setup);



    const { form, handleChange, setForm, setInForm } = useForm(user? user : {});
    const dispatch = useDispatch();

    function buttonClicked(e) {

      
      

      if(document.getElementById('manager').getElementsByClassName('MuiButton-label')[0].innerHTML == 'Edit') {
        setDisabledInput(false);
        document.getElementById('manager').getElementsByClassName('MuiButton-label')[0].innerHTML = 'Done'
      } else {
        dispatch(Action.updateProfile(form));
        document.getElementById('manager').getElementsByClassName('MuiButton-label')[0].innerHTML = 'Edit'
        setDisabledInput(true);
      }

    }

    function handlePasswordChange(e) {

      if(form.newPassword == form.newPassword2) {

        setConfirmErr("");

        dispatch(Action.changePassword({
          currentPassword: form.currentPassword,
          newPassword: form.newPassword
        }));

      } else {

          setConfirmErr("New password doesn't match");

      }

      

    }


    if(!user) {
      return null
    }

    

    return (
        <>
        <Helmet>
          <title>{`Profile | ${setup.organizationName}`}</title>
        </Helmet>
        <Box minHeight="1rem" >
          
        </Box>
      
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="6rem"
        classes={{ root: classes.containerRoot }}
      >

<Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                
                subheader={
                  
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
 
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        style={{
                            color: 'primary',
                          
                        }}
                        marginBottom="0!important"
                      >
                        <Typography  color="primary" style={{fontWeight: 'bold'}}>My Account</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          id="manager"
                          onClick={buttonClicked}
                        >
                          Edit
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                }
                style={{
                  background:'#F7FAFC'
                }}
               
               
              ></CardHeader>
              <CardContent>
                <Box
                  component={Typography}
                  variant="h4"
                  color={'#57737A' + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: classes.typographyRootH6 }}
                >
                  USER INFORMATION
                </Box>
                <div className={classes.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>First Name</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            name='firstName'
                            onChange={handleChange}
                            value={form.firstName}
                            disabled={disabledInput}
                            autoComplete="off"
                            type="text"
                            placeholder="Name"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Last Name</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            disabled={disabledInput}
                            name='lastName'
                            onChange={handleChange}
                            value={form.lastName}
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            placeholder="Name"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Email Address</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            disabled={disabledInput}
                            component={FilledInput}
                            name='email'
                            onChange={handleChange}
                            value={form.email}
                            autoComplete="off"
                            type="email"
                            placeholder="Email address"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormGroup>
                        <FormLabel>Phone Number</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            disabled={disabledInput}
                            name='phoneNumber'
                            onChange={handleChange}
                            value={form.phoneNumber}
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                            placeholder="Phone Number"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  
                </div>
                <Box
                  component={Divider}
                  marginBottom="1.5rem!important"
                  marginTop="1.5rem!important"
                />
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: classes.typographyRootH6 }}
                >
                  About Me
                </Box>
                <div className={classes.plLg4}>
                  
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Employee Id</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            name='employeeId'
                            onChange={handleChange}
                            value={form.employeeId}
                            type="text"
                            disabled
                            
                            inputProps={{ className: classes.input }}
                            placeholder="employee ID"
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Role</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            name='role'
                            onChange={handleChange}
                            value={form.role}
                            disabled
                            type="text"
                            placeholder="Role"
                          />
                        </FormControl>
                      </FormGroup>
                      
                    </Grid>
                    
                  </Grid>
                </div>
                
                
                
              </CardContent>
            </Card>

            

      </Container>

      <Container
        maxWidth={false}
        component={Box}
        marginTop="2rem"
        classes={{ root: classes.containerRoot }}
      >

      <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }} style={{marginBottom: '40px'}}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        style={{
                            color: 'primary'
                        }}
                        marginBottom="0!important"
                      >
                        <Typography color="primary" style={{fontWeight: 'bold'}}>Change Password</Typography>
                      </Box>
                    </Grid>
                    
                  </Grid>
                }
                style={{
                  background:'#F7FAFC'
                }}
              ></CardHeader>
              <CardContent>

              

                <div className={classes.plLg4}>
                <Grid container justify = "center" fontSize="80%" fontWeight="400" component="small">
                {<Typography style={{color:"#B13021"}}> {confirmErr} </Typography> }
                
              </Grid>

                <Formsy onValidSubmit={handlePasswordChange}>
                  
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Enter current password</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="password"
                            required
                            name='currentPassword'
                            onChange={handleChange}
                            value={form.currentPassword}
                            
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    
                    
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Enter New Password</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            required
                            type="password"
                            name='newPassword'
                            onChange={handleChange}
                            value={form.newPassword}
                           
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Confirm New Password</FormLabel>
                        <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            required
                            type="password"
                            name='newPassword2'
                            onChange={handleChange}
                            value={form.newPassword2}
                            
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item >
                   
                    <Box
                         marginTop="22px"
                          width="100%"
                          
                        >
                    <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          size="medium"
                          style={{background: '#e30c57', border: '#e30c57', paddingLeft: '40px' ,
                      paddingRight: '40px', borderRadius: '5px',
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)"}}
                          
                        >
                          Confirm
                        </Button>

                        

                        </Box>
                    </Grid>
                    
                  </Grid>

                  </Formsy>
                  
                </div>

              </CardContent>

              </Card>

              </Container>

      </>

    )
}

export default Profile;