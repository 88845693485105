import * as Actions from '../../actions/user';

const initialState = {
    users: null,
    user: null,
    pageNumbers: null,
    total: null
};

const user = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_USERS:
        {
            return {
                ...initialState,
                users  : action.payload,
                pageNumbers : action.pageNumbers,
                total: action.total
            };
        }
        case Actions.GET_USER:
        {
            return {
                ...initialState,
                user  : action.payload
            };
        }
        default:
        {
            return state
        }
    }
};

export default user;