import React, {useEffect} from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { useDispatch, useSelector } from 'react-redux';
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import HeaderClasses from "assets/theme/components/header.js";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import * as Actions from 'store/actions/user';
import * as requestAction from 'store/actions/request';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet'

// @material-ui/icons components
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

// core components
import FirstHeader from "views/common/Headers/FirstHeader.js";



import componentStyles from "assets/theme/views/admin/dashboard.js";
import tableStyles from "assets/theme/views/admin/tables.js";

const useStyles = makeStyles(componentStyles);
const useTableStyles = makeStyles(tableStyles);

const useHeaderStyles = makeStyles(HeaderClasses);

function AdminDashboard() {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const theme = useTheme();
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const users = useSelector(({user}) => user.users);
  const user = useSelector(({auth}) => auth.user.data);
  const setup = useSelector(({setup}) => setup.setup);
  const requests = useSelector(({request}) => request.requests);
  const total = useSelector(({user}) => user.total);
  const pending = useSelector(({request}) => request.pending);
  const totalRequests = useSelector(({request}) => request.total);
  const executing = useSelector(({request}) => request.accepted);
  const dispatch = useDispatch();



 

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    dispatch(Actions.getUsers(1));
    dispatch(requestAction.getAllRequests(1));
  }, [dispatch]);

  if(!users || !requests || !setup) {
    return  null
  }

  return (
    <>
        <Helmet>
          <title>{`Dashboard | ${setup.organizationName}`}</title>
        </Helmet>
      <FirstHeader requests={totalRequests} pending={pending} executing={executing}/>
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        marginBottom="3rem"
        classes={{ root: classes.containerRoot }}
      >
        
        <Grid container component={Box} marginTop="8rem">
          <Grid
            item
            
            xl={6}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot }}
          >

          <Card classes={{ root: classes.cardRoot }}>
              <CardHeader style={{borderBottom: "none"}}
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        style={{
                          color:"#E15638"
                        }}
                        marginBottom="0!important"
                      >
                        AEP power tools
                      </Box>
                    </Grid>
                    
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <TableContainer>
                <Box
                  component={Table}
                  alignItems="center"
                  marginBottom="0!important"
                >
                  
                  <TableBody>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        UDP
                      </TableCell>
                      
                      <TableCell align="right" style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }}>
                          <Box>
                            <span style={{
                              fontSize: '14px'
                            }}> Up </span>
                            <Box
                              marginRight="20px"
                              marginLeft="10px"
                              component="i"
                              width=".375rem"
                              height=".375rem"
                              borderRadius="50%"
                              display="inline-block"
                              className={
                                `${tableClasses.verticalAlignMiddle} + " " + ${  tableClasses.bgSuccess}`
                              }
                            ></Box>
                              
                          </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        DRP
                      </TableCell>
                      
                      <TableCell align="right" style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }}>
                      <Box>
                            <span style={{
                              fontSize: '14px'
                            }}> Down </span>
                            <Box
                              marginRight="20px"
                              marginLeft="10px"
                              component="i"
                              width=".375rem"
                              height=".375rem"
                              borderRadius="50%"
                              display="inline-block"
                              className={
                                `${tableClasses.verticalAlignMiddle} + " " + ${  tableClasses.bgError}`
                              }
                            ></Box>
                              
                          </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        Ansible
                      </TableCell>
                      
                      <TableCell align="right" style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }}>
                      <Box >
                            <span style={{
                              fontSize: '14px'
                            }}> Up </span>
                            <Box
                              marginRight="20px"
                              marginLeft="10px"
                              component="i"
                              width=".375rem"
                              height=".375rem"
                              borderRadius="50%"
                              display="inline-block"
                              className={
                                `${tableClasses.verticalAlignMiddle} + " " + ${  tableClasses.bgSuccess}`
                              }
                            ></Box>
                              
                          </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        abc
                      </TableCell>
                      
                      <TableCell align="right" style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }}>
                      <Box >
                            <span style={{
                              fontSize: '14px'
                            }}> Up </span>
                            <Box
                              marginRight="20px"
                              marginLeft="10px"
                              component="i"
                              width=".375rem"
                              height=".375rem"
                              borderRadius="50%"
                              display="inline-block"
                              className={
                                `${tableClasses.verticalAlignMiddle} + " " + ${  tableClasses.bgSuccess}`
                              }
                            ></Box>
                              
                          </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead +
                            " " +
                            classes.borderBottomUnset,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        xyz
                      </TableCell>
                      
                      <TableCell align="right" style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.borderBottomUnset,
                        }}
                      >
                        <Box >
                            <span style={{
                              fontSize: '14px'
                            }}> Down </span>
                            <Box
                              marginRight="20px"
                              marginLeft="10px"
                              component="i"
                              width=".375rem"
                              height=".375rem"
                              borderRadius="50%"
                              display="inline-block"
                              className={
                                `${tableClasses.verticalAlignMiddle} + " " + ${  tableClasses.bgError}`
                              }
                            ></Box>
                              
                          </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Box>
              </TableContainer>
            </Card>
            
          </Grid>

          <Grid
            item
            
            xl={6}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot }}
          >

<Card classes={{ root: classes.cardRoot }}>
              <CardHeader style={{borderBottom: "none"}}
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        style={{
                          color:"#E15638"
                        }}
                        marginBottom="0!important"
                      >
                        Systems Provisioned
                      </Box>
                    </Grid>
                    
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <TableContainer>
                <Box
                  component={Table}
                  alignItems="center"
                  marginBottom="0!important"
                >
                  
                  <TableBody>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        UDP
                      </TableCell>
                      
                      <TableCell style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }} align="center">                      
                          900                      
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        DRP
                      </TableCell>
                      
                      <TableCell style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }} align="center">                      
                          900                      
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        Ansible
                      </TableCell>
                      
                      <TableCell style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }} align="center">                      
                          900                      
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        abc
                      </TableCell>
                      
                      <TableCell style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }} align="center">                      
                          900                      
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead +
                            " " +
                            classes.borderBottomUnset,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        xyz
                      </TableCell>
                      
                      <TableCell style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }} align="center">                      
                          900                      
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Box>
              </TableContainer>
            </Card>

          </Grid>
          
        </Grid>

        <Grid container  component={Box} marginTop="1rem">
          <Grid
            item
            
            xl={6}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot }}
          >

<Card
              classes={{
                root: classes.cardRoot,
              }}
             
              
            >
              <CardHeader style={{borderBottom: "none"}}
                subheader={
                  <Grid
                    container
                   
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto" >
                      <Box
                        component={Typography}
                        variant="h3"
                        style={{
                          color:"#E15638"
                        }}
                        marginBottom="0!important"
                      >
                        Total Users
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Typography>
                          {total}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <TableContainer  style={{
                maxWidth: '100%',
                overflow: 'hidden'
              }}>
                <Box
                  component={Table}
                  alignItems="center"
                  marginBottom="0!important"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Role
                      </TableCell>
                      <TableCell style={{borderBottom: "none"}}
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootHead,
                        }}
                      >
                        Employee Id
                      </TableCell>
                     
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {users.map((userData, i) =>

                    <TableRow>
                    <TableCell style={{borderBottom: "none"}}
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootBodyHead,
                      }}
                      component="th"
                      variant="head"
                      scope="row"
                    >
                      {userData.email}
                    </TableCell>
                    <TableCell style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem">
                    <Box
                      marginRight="10px"
                      component="i"
                      width=".375rem"
                      height=".375rem"
                      borderRadius="50%"
                      display="inline-block"
                      className={
                        `${tableClasses.verticalAlignMiddle} + " " + ${ userData.role == 'requestor' ? tableClasses.bgPending: userData.role == 'superAdmin' ? tableClasses.bgSuccess: userData.role == 'admin' ? tableClasses.bgSuccess:  tableClasses.bgPrimary}`
                      }
                    ></Box>
                      {userData.role}
                      </Box>
                    </TableCell>
                    <TableCell style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }}>
                      {userData.employeeId}
                    </TableCell>

                    </TableRow>

                  )}
                    
                   
                    <TableRow>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot +
                            " " +
                            classes.tableCellRootBodyHead,
                        }}
                        component="th"
                        variant="head"
                        scope="row"
                      >
                        
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>

                      <Link style={{color: '#57737A', textDecoration: 'none'}} to={`/${user.role}/manageusers`}> <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                        flexWrap: 'wrap',
                    }}>see all <ChevronRightIcon /> </div> </Link>
                        
                      </TableCell>
                      
                    </TableRow>
                    
                    
                  </TableBody>
                </Box>
              </TableContainer>
            </Card>

          </Grid>

          <Grid
            item
            
            xl={6}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot }}
          >

        <Card
              classes={{
                root: classes.cardRoot,
              }}
            >
              <CardHeader style={{borderBottom: "none"}}
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        style={{
                          color:"#E15638"
                        }}
                        marginBottom="0!important"
                      >
                        Recent Requests
                      </Box>
                    </Grid>
                    
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <TableContainer>
                <Box
                  component={Table}
                  alignItems="center"
                  marginBottom="0!important"
                >
                  
                  <TableBody>
                  {requests.map((request, i) =>


                    <TableRow>
                    <TableCell style={{borderBottom: "none"}}
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootBodyHead,
                      }}
                      component="th"
                      variant="head"
                      scope="row"
                    >
                      {request.environment}
                    </TableCell>
                    <TableCell align="right" style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem">
                    <Box
                      marginRight="10px"
                      component="i"
                      
                      width=".375rem"
                      height=".375rem"
                      borderRadius="50%"
                      display="inline-block"
                      className={
                        `${tableClasses.verticalAlignMiddle} + " " + ${ request.status == 'pending' || request.status == 'approved' ? tableClasses.bgPending: request.status == 'rejected' || request.status == 'failed' ? tableClasses.bgError:  tableClasses.bgSuccess}`
                      }
                    ></Box>
                      {request.status == 'approved' ? 'Executing' : capitalizeFirstLetter(request.status)}
                      </Box>
                    </TableCell>


                    </TableRow>


                  )}
                    
                    

                    <TableRow>
                     
                      <TableCell style={{borderBottom: "none", borderTop: "none"}}>
                        
                      </TableCell>
                      
                      <TableCell style={{borderBottom: "none"}} classes={{ root: classes.tableCellRoot }}>
                      <Link style={{color: '#57737A', textDecoration: 'none'}} to={`/${user.role}/resourcerequests`}> <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                        flexWrap: 'wrap',
                    }}>see all <ChevronRightIcon /> </div> </Link>
                        
                      </TableCell>
                      
                    </TableRow>
                    
                    
                  </TableBody>
                </Box>
              </TableContainer>
            </Card>

          </Grid>

        </Grid>
      </Container>
    </>
  );
}

export default AdminDashboard;
