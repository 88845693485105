import axios from "axios";
import { browserHistory } from 'react-router'
import * as snackAction from 'store/actions/helper';
export const USER_CREATED = 'USER_CREATED';



export function createUser(model, props)
{

    return (dispatch) =>
        axios
            .post(`api/v1/auth/createuser`, model)
            .then(res => {  
                dispatch(snackAction.showMessage('User created', "success"));

                dispatch({
                    type   : USER_CREATED
                });

                props.history.push('/superadmin/manageUsers')

            }).catch(error => {
                
                dispatch(snackAction.showMessage(error.response.data.error, "error"));
                

        })
}