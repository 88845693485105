import React, {useEffect} from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import CardActions from "@material-ui/core/CardActions";
import Pagination from "@material-ui/lab/Pagination";
import { TextField } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { useSelector, useDispatch } from 'react-redux';
// core components
import { CardMedia } from "@mui/material";
import * as Action from 'store/actions/auth';
import HeaderClasses from "assets/theme/components/header.js";
import useForm from "utils/useForm";
import componentStyles from "assets/theme/views/admin/dashboard.js";
import tableStyles from "assets/theme/views/admin/tables.js";
import { Helmet } from 'react-helmet'
  
  const useStyles = makeStyles(componentStyles);
  const useTableStyles = makeStyles(tableStyles);
  
  const useHeaderStyles = makeStyles(HeaderClasses);

 

  function RequestorUtilization() {

    const classes = useStyles();
  
    const headerClasses = useHeaderStyles();
    const tableClasses = useTableStyles();
    const theme = useTheme();
    const [activeNav, setActiveNav] = React.useState(1);
    const [disabledInput, setDisabledInput] = React.useState(true);
    const user = useSelector(({auth}) => auth.user.data); 
    const setup = useSelector(({setup}) => setup.setup);


    const { form, handleChange, setForm, setInForm } = useForm(user? user : {});
    const dispatch = useDispatch();

    const bull = (
        <Box
          component="span"
          sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
          •
        </Box>
      );


    if(!user) {
      return null
    }

    

    return (
        <>
        <Box minHeight="1rem" >
          
        </Box>
      
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="6rem"
        classes={{ root: classes.containerRoot }}
      >
        

        <Grid container>
          <Grid container style={{
                marginBottom: '30px'
            }}>
            <Grid item xs={12} lg={4}>
                <CardMedia
                style={{
                    width: "100%",
                    margin: "auto",
                    height: "186.1px",
                   
                    boxShadow: "0px 0px 29.4674px rgba(136, 152, 170, 0.15)",
                    borderRadius: "5.52514px",
                    border: "0.920857px solid rgba(0, 0, 0, 0.05)"
                }}
                component="iframe"
                image="http://183.87.231.86:4300/d-solo/rYdddlPWk/node-exporter-full?orgId=1&refresh=1m&from=164
                3280965785&to=1643367365785&theme=light&panelId=20"
                />
            </Grid>

            <Grid item xs={12} lg={4}>
                <CardMedia
                style={{
                    width: "100%",
                    margin: "auto",
                    height: "186.1px",
                  
                    boxShadow: "0px 0px 29.4674px rgba(136, 152, 170, 0.15)",
                    borderRadius: "5.52514px",
                    border: "0.920857px solid rgba(0, 0, 0, 0.05)"
                }}
                component="iframe"
                image="http://183.87.231.86:4300/d-solo/rYdddlPWk/node-exporter-full?orgId=1&refresh=1m&from=164
                3281023593&to=1643367423593&theme=light&panelId=155"
                />
            </Grid>

            <Grid item xs={12} lg={4}>
                <CardMedia
                style={{
                    width: "100%",
                    margin: "auto",
                    height: "186.1px",
                   
                    boxShadow: "0px 0px 29.4674px rgba(136, 152, 170, 0.15)",
                    borderRadius: "5.52514px",
                    border: "0.920857px solid rgba(0, 0, 0, 0.05)"
                }}
                component="iframe"
                image="http://183.87.231.86:4300/d-solo/rYdddlPWk/node-exporter-full?orgId=1&refresh=1m&from=164
                3281087058&to=1643367487059&theme=light&panelId=19"
                />
            </Grid>

            </Grid>

            <Grid container>

            <Grid item xs={12} lg={4}>
                <CardMedia
                style={{
                    width: "100%",
                    margin: "auto",
                    height: "186.1px",
                  
                    boxShadow: "0px 0px 29.4674px rgba(136, 152, 170, 0.15)",
                    borderRadius: "5.52514px",
                    border: "0.920857px solid rgba(0, 0, 0, 0.05)"
                }}
                component="iframe"
                image="http://183.87.231.86:4300/d-solo/rYdddlPWk/node-exporter-full?orgId=1&refresh=1m&from=164
                3281117639&to=1643367517639&theme=light&panelId=16"
                />
            </Grid>

            <Grid item xs={12} lg={4}>
                <CardMedia
                style={{
                    width: "100%",
                    margin: "auto",
                    height: "186.1px",
                  
                    boxShadow: "0px 0px 29.4674px rgba(136, 152, 170, 0.15)",
                    borderRadius: "5.52514px",
                    border: "0.920857px solid rgba(0, 0, 0, 0.05)"
                }}
                component="iframe"
                image="http://183.87.231.86:4300/d-solo/rYdddlPWk/node-exporter-full?orgId=1&refresh=1m&from=164
                3281337338&to=1643367737338&theme=light&panelId=21"
                />
            </Grid>

            <Grid item xs={12} lg={4}>
                <CardMedia
                style={{
                    width: "100%",
                    margin: "auto",
                    height: "186.1px",
                   
                    boxShadow: "0px 0px 29.4674px rgba(136, 152, 170, 0.15)",
                    borderRadius: "5.52514px",
                    border: "0.920857px solid rgba(0, 0, 0, 0.05)"
                }}
                component="iframe"
                image="http://183.87.231.86:4300/d-solo/rYdddlPWk/node-exporter-full?orgId=1&refresh=1m&from=164
                3281380261&to=1643367780261&theme=light&panelId=154"
                />
            </Grid>

            </Grid>

            <Grid container style={{
                marginTop: '30px'
            }}>

            <Grid item xs={12} style={{
              marginRight: '27px'
            }} lg={2}>
                <CardMedia
                style={{
                    

                    height: "110.95px",
                    width: "180.77px",
                   
                    boxShadow: "0px 0px 29.4674px rgba(136, 152, 170, 0.15)",
                    borderRadius: "5.52514px",
                    border: "0.920857px solid rgba(0, 0, 0, 0.05)"
                }}
                component="iframe"
                image="http://183.87.231.86:4300/d-solo/rYdddlPWk/node-exporter-full?orgId=1&refresh=1m&from=164
                3281529814&to=1643367929814&theme=light&panelId=75"
                />
            </Grid>

            <Grid item xs={12} style={{
              marginRight: '27px'
            }} lg={2}>
                <CardMedia
                style={{
                    
                    margin: "auto",
                    height: "110.95px",
                    width: "180.77px",
                   
                    boxShadow: "0px 0px 29.4674px rgba(136, 152, 170, 0.15)",
                    borderRadius: "5.52514px",
                    border: "0.920857px solid rgba(0, 0, 0, 0.05)"
                }}
                component="iframe"
                image="http://183.87.231.86:4300/d-solo/rYdddlPWk/node-exporter-full?orgId=1&refresh=1m&from=164
                3281545293&to=1643367945293&theme=light&panelId=18"
                />
            </Grid>

            

            

            <Grid item xs={12} style={{
              marginRight: '27px'
            }} lg={2}>
                <CardMedia
                style={{
                   
                    margin: "auto",
                    height: "110.95px",
                    width: "180.77px",
                    boxShadow: "0px 0px 29.4674px rgba(136, 152, 170, 0.15)",
                    borderRadius: "5.52514px",
                    border: "0.920857px solid rgba(0, 0, 0, 0.05)"
                }}
                component="iframe"
                image="http://183.87.231.86:4300/d-solo/rYdddlPWk/node-exporter-full?orgId=1&refresh=1m&from=164
                3281425490&to=1643367825491&theme=light&panelId=14"
                />
            </Grid>

            <Grid item xs={12} style={{
              marginRight: '27px'
            }} lg={2}>
                <CardMedia
                style={{
                   
                    margin: "auto",
                    height: "110.95px",
                    width: "180.77px",
                    boxShadow: "0px 0px 29.4674px rgba(136, 152, 170, 0.15)",
                    borderRadius: "5.52514px",
                    border: "0.920857px solid rgba(0, 0, 0, 0.05)"
                }}
                component="iframe"
                image="http://183.87.231.86:4300/d-solo/rYdddlPWk/node-exporter-full?orgId=1&refresh=1m&from=164
                3281473812&to=1643367873812&theme=light&panelId=15"
                />
            </Grid>

            <Grid item xs={12} style={{
              marginRight: '27px'
            }} lg={2}>
                <CardMedia
                style={{
                   
                    margin: "auto",
                    height: "110.95px",
                    width: "180.77px",
                    boxShadow: "0px 0px 29.4674px rgba(136, 152, 170, 0.15)",
                    borderRadius: "5.52514px",
                    border: "0.920857px solid rgba(0, 0, 0, 0.05)"
                }}
                component="iframe"
                image="http://183.87.231.86:4300/d-solo/rYdddlPWk/node-exporter-full?orgId=1&refresh=1m&from=164
                3281498712&to=1643367898712&theme=light&panelId=23"
                />
            </Grid>

            </Grid>

        </Grid>
  
        </Container>

      </>

    )
}

export default RequestorUtilization;