import { Grid, Typography } from "@material-ui/core";
import { useSelector } from 'react-redux';

const SidebarFooter = () => {

  const setup = useSelector(({setup}) => setup.setup);  

  if(!setup) {
    return null
  }

    return (
        <>

          <div  style={{
            width: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: '#e7ffe1',
            position: 'absolute',
            paddingBottom: '10px',
            paddingTop: '10px',
            
            bottom: 0
          }} >

         
          <div  >
            <img  width="80px" style={{
              
            }} src={require("assets/img/brand/logo.png") }/>
          </div>
          <div  style={{
            marginTop: '20px',
            paddingLeft: '3px'
          }} >
            <Typography color="primary" style={{
              fontSize: "13px",
              width: '160px',
              fontWeight: 'bold',
            }}>AEP 2.0</Typography>
            
            {!setup.removeLogos ? <Typography display="flex" color="primary" style={{
              fontSize: "13px",
              width: '160px',
              display: 'flex'
            }}>Powered by  <img style={{marginLeft:"2px"}} width="60px" src={require("assets/img/brand/Datamato_Logo.png") }/> </Typography> : ''}
            
          </div>
          
          </div>

          </>

          )
};

export default SidebarFooter;