import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons components
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import GroupAdd from "@material-ui/icons/GroupAdd";
import { useDispatch, useSelector } from 'react-redux';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import InsertChartOutlined from "@material-ui/icons/InsertChartOutlined";
import PieChart from "@material-ui/icons/PieChart";
import MemoryIcon from '@mui/icons-material/Memory';
import SdCardIcon from '@mui/icons-material/SdCard';
import StorageIcon from '@mui/icons-material/Storage';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';

// core components
import CardStats from "views/common/Cards/CardStats.js";

import componentStyles from "assets/theme/components/header.js";

const useStyles = makeStyles(componentStyles);

const SecondHeader = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const user = useSelector(({auth}) => auth.user.data);

  return (
    <>
      <div className={classes.header}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <div>
            <Grid container>
              
              <Grid item xl={4} lg={6} xs={12}>
                <CardStats
                  subtitle="REQUESTS"
                  title={props.requests}
                  icon={SdCardIcon}
                  color="bgWarning"
                 
                />
              </Grid>
              <Grid item xl={4} lg={8} xs={12}>
                <CardStats
                  subtitle="Instances approved"
                  title={props.approved}
                  icon={StorageIcon}
                  color="bgWarningLight"
                 
                />
              </Grid>

              {user.role == 'approver' ?
              
              <Grid item xl={4} lg={8} xs={12}>
              <CardStats
                subtitle="Pending Request"
                title={props.pending}
                icon={PendingActionsIcon}
                color="bgWarningLight"
               
              />
            </Grid> : ''
            
            }

              
              
              
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SecondHeader;
