import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from 'store/actions/auth';
import jwtService from 'services/jwtService';
import browserHistory from 'browserHistory';
import { customHistory } from 'index';

var properties;


class Authorization extends Component {
    constructor(props)
    {
        super(props);
        properties = props;
        this.jwtCheck();
    }

    
    
    jwtCheck = () => {

       
        jwtService.on('onAutoLogin', () => {

            /**
             * Sign in and retrieve user data from Api
             */
            jwtService.signInWithToken()
                .then(data => {
                    this.props.setUserData(data.user, data.setup);
                    // if(customHistory.location.pathname == '/auth/login') {
                    //     customHistory.push(`/${data.user.role}/index`);
                    // }
                })
                .catch(error => {
                    browserHistory.push('/auth/login');
                })
        });

        jwtService.on('onAutoLogout', (message) => {
            this.props.logout(browserHistory);
        });

        jwtService.init();
    };

    render()
    {
        const {children} = this.props;

        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
            logout             : Actions.logoutUser,
            setUserData        : Actions.setUserData
        },
        dispatch);
}

export default connect(null, mapDispatchToProps)(Authorization);
