import * as Actions from '../../actions/auth';


const initialState = {
    data: null
};

const login = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.SET_USER_DATA:
        {
            return {
                ...initialState,
                data  : action.payload
            };
        }
        default:
        {
            return state
        }
    }
};

export default login;