import React, { useEffect, useRef, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Snackbar, SnackbarContent } from "@material-ui/core";

// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// core components
import componentStyles from "assets/theme/views/auth/main.js";

import useForm from "utils/useForm";
import { useDispatch, useSelector } from "react-redux";
import Formsy from "formsy-react";
import * as Action from "../../store/actions/auth";
import { Helmet } from 'react-helmet'

const useStyles = makeStyles(componentStyles);

function CreateSuperUser(props) {

  const classes = useStyles();
  const theme = useTheme();

  const defaultForm = {
    firstName: "",
    lastName: "",
    email: "",
    employeeId: "",
    phoneNumber: "",
    password: "",
  };

  const [showSnack, setShowSnack] = useState(false);

  const { form, handleChange, resetForm } = useForm(defaultForm);
  const dispatch = useDispatch();

  const formRef = useRef(null);

  function handleSubmit(event) {


    dispatch(Action.createSuperUser(form, props.history));
    dispatch(Action.setup(props.history.location.form));
  }

  useEffect(() => {
    if (!props.history.location.form) {
      dispatch(Action.checkExistence(props.history));
    }
  }, []);

  return (
    <>
    <Helmet>
          <title>{`Create Super User`}</title>
        </Helmet>
      <Grid>
        <Snackbar
          open={showSnack}
          autoHideDuration={1000}
          onClose={() => {
            setShowSnack(false);
          }}
        >
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            This is an error alert — check it out!
          </Alert>
        </Snackbar>

        <Card
          style={{
            boxShadow:
              "0px -2px 4px rgba(234, 234, 234, 0.25), 0px 2px 8px rgba(50, 50, 93, 0.15)",
            borderRadius: "6px",
          }}
        >
          <CardHeader
            subheader={<Typography>Create Super User</Typography>}
            style={{
              border: "1px solid white",
            }}
          ></CardHeader>
          <CardContent>
            <Formsy onValidSubmit={handleSubmit} ref={formRef}>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <FilledInput
                      autoComplete="off"
                      name="firstName"
                      value={form.firstName}
                      onChange={handleChange}
                      type="text"
                      required
                      placeholder="First Name"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <FilledInput
                      autoComplete="off"
                      name="lastName"
                      value={form.lastName}
                      type="text"
                      required
                      onChange={handleChange}
                      placeholder="Last Name"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <FilledInput
                    autoComplete="off"
                    name="email"
                    type="email"
                    value={form.email}
                    onChange={handleChange}
                    required
                    placeholder="Email"
                  />
                </FormControl>
              </Grid>

              <Grid>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <FilledInput
                    autoComplete="off"
                    name="phoneNumber"
                    startAdornment={
                      <InputAdornment
                        style={{ borderRight: "2px solid #BDBECA" }}
                        position="start"
                      >
                        <Typography style={{ paddingRight: "8px" }}>
                          +91{" "}
                        </Typography>
                      </InputAdornment>
                    }
                    value={form.phoneNumber}
                    onChange={handleChange}
                    type="text"
                    required
                    placeholder="Phone Number"
                  />
                </FormControl>
              </Grid>

              <Grid>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <FilledInput
                    autoComplete="off"
                    name="employeeId"
                    value={form.employeeId}
                    onChange={handleChange}
                    type="text"
                    required
                    placeholder="Employee ID"
                  />
                </FormControl>
              </Grid>

              <Grid>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <FilledInput
                    autoComplete="off"
                    name="password"
                    type="password"
                    value={form.password}
                    onChange={handleChange}
                    required
                    placeholder="Password"
                  />
                </FormControl>
              </Grid>

              <Box textAlign="center">
                <Button
                  style={{
                    background: "#e30c57",
                    border: "#e30c57",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    borderRadius: "5px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  }}
                  type="submit"
                  variant="contained"
                >
                  CREATE
                </Button>
              </Box>

              <Box textAlign="right">
                <Typography>Step 2/2</Typography>
              </Box>
            </Formsy>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default CreateSuperUser;
