import axios from "axios";
import * as snackAction from 'store/actions/helper';


export function bulkRejectRequest(requests, rejectReason, props)
{

    return (dispatch) =>
        axios
            .post(`api/v1/request/bulkrejectrequests`, {requests, rejectReason})
            .then(res => {  
                dispatch(snackAction.showMessage('Requests rejected successfully', "success"));
                
                props.history.push('/approver/resourcerequests')

            }).catch(error => {
                dispatch(snackAction.showMessage(error.message, "error"));
                console.log(error)

        })
}