import axios from "axios";

export const GET_USER = 'GET_USER';


export function getUser(userId)
{

    return (dispatch) =>
        axios
            .get(`api/v1/auth/getuser/${userId}`)
            .then(res => {  

                dispatch({
                    type   : GET_USER,
                    payload: res.data.user
                });

            }).catch(error => {

                console.log(error)

        })
}