import axios from "axios";

export const GET_USERS = 'GET_USERS';


export function getUsers(page)
{

    return (dispatch) =>
        axios
            .get(`api/v1/auth/getusers?page=${page}`)
            .then(res => {  

                dispatch({
                    type   : GET_USERS,
                    payload: res.data.users,
                    pageNumbers: res.data.pageNumbers,
                    total: res.data.total
                });

            }).catch(error => {

                console.log(error)

        })
}