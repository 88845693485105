import React, {useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import clsx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Formsy from 'formsy-react';
import Typography from "@material-ui/core/Typography";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import useForm from "utils/useForm";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { OutlinedInput } from "@mui/material";
import Select from '@mui/material/Select';
import grey from '@material-ui/core/colors/grey';
import InputBase from '@material-ui/core/InputBase';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
// core components
import { styled } from '@mui/material/styles';
import boxShadows from "assets/theme/box-shadow.js";
import HeaderClasses from "assets/theme/components/header.js";
import * as Action from 'store/actions/user';
import componentStyles from "assets/theme/views/admin/dashboard.js";
import tableStyles from "assets/theme/views/admin/tables.js";
import { Divider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from 'react-helmet'

  
  const useStyles = makeStyles(componentStyles);
  const useTableStyles = makeStyles(tableStyles);
  
  const useHeaderStyles = makeStyles(HeaderClasses);

  
  const useStyles2 = makeStyles( theme => ({
    container: {
      width: "100%",
      height: "100vh",
     
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    select: {
      height: 46,
      cursor: 'pointer',
      textAlign: 'left',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      border: `0px`,
      outline: '0px',
      borderWidth: '0px',
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
    },
    selectdisabled: {
      color: grey[500],
    },
   
    menuitemhidden: {
      display: "none"
    },
  })); 



  function CreateUser(props) {

    const classes = useStyles();

    const classes2 = useStyles2();
    const [value, setValue] = useState("requestor");
    const [showPlaceholder, setShowPlaceholder] = useState(value === "none");

    const defaultForm = {
        firstName: '',
        lastName: '',
        email: '',
        employeeId: '',
        phoneNumber: '',
        password: ''
      };
      const [role, setRole] = useState('');
      const user = useSelector(({auth}) => auth.user.data); 
      const {form, handleChange, resetForm} = useForm(defaultForm);
      const setup = useSelector(({setup}) => setup.setup);
      const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(3),
        },
        '& .Mui-disabled': {
          backgroundColor: '#efefef !important'
        },
        '& .MuiInputBase-input': {
        boxShadow: boxShadows.inputBoxShadow,
          position: 'relative',
          backgroundColor: theme.palette.background.paper,
         
          borderRadius: ".375rem",
          border: "none",
          fontSize: 15,
          padding: '10px 26px 10px 22px',
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          // Use the system font instead of the default Roboto font.
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
          '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
          },
        },
      }));

      const dispatch = useDispatch();

    function handleSubmit(event)
        {
            form.role = value;
            dispatch(Action.createUser(form, props))
            // dispatch(Action.createSuperUser(form, props.history))
            // dispatch(Action.setup(props.history.location.form))

        }

        const handleSelectChange = (event) => {
            setRole(event.target.value);
          };
        const formRef = useRef(null);



    return (
        <>
        <Helmet>
          <title>{`Create User | ${setup.organizationName}`}</title>
        </Helmet>
        <Box minHeight="1rem" >
          
        </Box>

        <Box paddingTop='55px' margin='20px'>
            <Link to={`/${user.role.toLowerCase()}/manageusers`} style={{color: '#57737A', textDecoration: 'none'}}>
                <Grid container spacing={2}>
                    <NavigateBeforeIcon /> <Typography>Back</Typography>
                </Grid>
            </Link>
        </Box>
        
      
        <Container
        maxWidth={false}
        component={Box}
        marginTop="1rem"
        classes={{ root: classes.containerProv }}
      >

          

<Card style={{
            boxShadow: "0px -2px 4px rgba(234, 234, 234, 0.25), 0px 2px 8px rgba(50, 50, 93, 0.15)", borderRadius: "6px", marginTop: '-35px'
            
          }} >

         <CardHeader  subheader={<Typography color="primary" style={{fontWeight: 'bold'}}>Enter details to create user</Typography>} style={{
                  background:'#F7FAFC'
                }}></CardHeader>
            <CardContent>

            <Formsy
                onValidSubmit={handleSubmit}
                ref={formRef}                   
            >      

                    <Grid container>
                    <Grid item xs={12} lg={6} >
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <FilledInput
                        autoComplete="off"
                        name="firstName"
                        value={form.firstName}
                        onChange={handleChange}
                        type="text"
                        required                     
                        placeholder="First Name"
                      />
                    </FormControl>
                    
                    </Grid>
                    <Grid item xs={12} lg={6}>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <FilledInput
                        autoComplete="off"
                        name="lastName"
                        value={form.lastName}
                        type="text"
                        required
                        onChange={handleChange}
                        placeholder="Last Name"
                       
                      />
                    </FormControl>
                    </Grid>
                    
                  </Grid>

                  <Grid >
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <FilledInput
                        autoComplete="off"
                        name="email"
                        type="email"
                        value={form.email}
                        onChange={handleChange}
                        required                        
                        placeholder="Email"
                        
                      />
                    </FormControl>
                    </Grid>

                    <Grid >
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <FilledInput
                        autoComplete="off"
                        name="phoneNumber"
                        startAdornment={
                          <InputAdornment style={{ borderRight: '2px solid #BDBECA'}} position="start" >
                            <Typography style={{ paddingRight: '8px'}}  >+91 </Typography>
                            
                          </InputAdornment>
                        }
                        value={form.phoneNumber}
                        onChange={handleChange}
                        type="text"
                        required                        
                        placeholder="Phone Number"
                        
                      />
                    </FormControl>
                    </Grid>

                    <Grid >
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <FilledInput
                        autoComplete="off"
                        name="employeeId"
                        value={form.employeeId}
                        onChange={handleChange}
                        type="text"
                        required                        
                        placeholder="Employee ID"
                        
                      />
                    </FormControl>
                    </Grid>

                    <Grid>

                        

                    <FormControl variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important">


                      <Select
                           
                           IconComponent={(props) => (<ExpandMoreIcon {...props}/>)}
                           input={<BootstrapInput name="deploymentEnvironment"
                            
                           onChange={(e) => setValue(e.target.value)} />}
                           value={value}
                           >
                       
                            
                            <MenuItem className={classes2.menuitem} key="1" value="admin" >Admin</MenuItem>
                            <Divider />
                            <MenuItem className={classes2.menuitem} key="2" value="approver" >Approver</MenuItem>
                            <Divider />
                            <MenuItem className={classes2.menuitem} key="3" value="requestor" >requestor</MenuItem>
                           
                       </Select>
                  
        {/* <Select
          value={value}
          defaultValue="none"
          fullWidth
          
          onChange={(e) => setValue(e.target.value)}
          onFocus={(e) => setShowPlaceholder(false)}
          onClose={(e) => setShowPlaceholder(e.target.value === undefined)}
          className={clsx(classes2.select, value === "none" ? classes2.selectdisabled : null)}
        >
          <MenuItem className={clsx(classes2.menuitem, !showPlaceholder ? classes2.menuitemhidden : null)} key="0" disabled value="none" >Role</MenuItem>
                <MenuItem className={classes2.menuitem} key="1" value="administrator" >Administrator</MenuItem>
                <Divider />
                <MenuItem className={classes2.menuitem} key="2" value="approver" >Approver</MenuItem>
                <Divider />
                <MenuItem className={classes2.menuitem} key="3" value="requestor" >requestor</MenuItem>
        </Select> */}
                    

                    </FormControl>

                   

                    </Grid>

                    <Grid >
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <FilledInput
                        autoComplete="off"
                        name="password"
                        type="password"
                        value={form.password}
                        onChange={handleChange}
                        required                        
                        placeholder="Password"
                        
                      />

                        

                    </FormControl>
                    </Grid>

                    <Box textAlign="center" >
                    <Button style={{background: '#e30c57', border: '#e30c57', paddingLeft: '40px' ,
                      paddingRight: '40px', borderRadius: '5px',
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)"}} type="submit" variant="contained" >
                        CREATE USER
                      </Button> 
                              
                  </Box>

                  
                </Formsy>

                    </CardContent>

                     
            </Card>

        </Container>

</>

)

}

export default CreateUser;