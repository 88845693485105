import React, {useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import clsx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import boxShadows from "assets/theme/box-shadow.js";
import Formsy from 'formsy-react';
import { styled } from '@mui/material/styles';
import Typography from "@material-ui/core/Typography";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import useForm from "utils/useForm";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { OutlinedInput } from "@mui/material";
import Select from '@mui/material/Select';
import grey from '@material-ui/core/colors/grey';
import InputBase from '@material-ui/core/InputBase';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
// core components
import * as Action from 'store/actions/auth';
import HeaderClasses from "assets/theme/components/header.js";
import { useDispatch, useSelector } from 'react-redux';
import componentStyles from "assets/theme/views/admin/dashboard.js";
import tableStyles from "assets/theme/views/admin/tables.js";
import { Divider } from "@material-ui/core";
import * as Actions from 'store/actions/user';
import { customHistory } from "index";  
import { Helmet } from 'react-helmet'

  const useStyles = makeStyles(componentStyles);
  const useTableStyles = makeStyles(tableStyles);
  
  const useHeaderStyles = makeStyles(HeaderClasses);

  
  
  const useStyles2 = makeStyles( theme => ({
    container: {
      width: "100%",
      height: "100vh",
     
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    select: {
      height: 46,
      cursor: 'pointer',
      textAlign: 'left',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      border: `0px`,
      outline: '0px',
      borderWidth: '0px',
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
    },
    selectdisabled: {
      color: grey[500],
    },
   
    menuitemhidden: {
      display: "none"
    },
  })); 

  

  let reRenderCounter = true;

  function EditUser(props) {

    const classes = useStyles();
    const theme = useTheme();
    let {userId} = props.match.params;
    const [disabledInput, setDisabledInput] = React.useState(true);
    const classes2 = useStyles2();
    
    const dispatch = useDispatch();

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
      'label + &': {
        marginTop: theme.spacing(3),
      },
    
      '& .Mui-disabled': {
        backgroundColor: '#efefef !important'
      }
      ,
      
      '& .MuiInputBase-input': {

      boxShadow: boxShadows.inputBoxShadow,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,

        border: 'white',
        borderRadius: ".375rem",
        fontSize: 15,
        padding: '10px 26px 10px 22px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
          borderRadius: 4,
          borderColor: '#80bdff',
          boxShadow: '0 0 0 .2rem rgba(0,123,255,.25)',
        },
      },
    }));


    


    const user = useSelector(({user}) => user.user);
    const setup = useSelector(({setup}) => setup.setup);
    const activeUser = useSelector(({auth}) => auth.user.data);
    const defaultForm = {
        firstName: '',
        lastName: '',
        email: '',
        employeeId: '',
        phoneNumber: '',
        password: ''
      };
      const [role, setRole] = useState('');
    
      const {form, handleChange, setForm} = useForm(user ? user : {});

      const [value, setValue] = useState("none");
      const [roleValue, setRoleValue] = useState('');

      function buttonClicked(e) {

        if(document.getElementById('manager').getElementsByClassName('MuiButton-label')[0].innerHTML == 'EDIT') {
          setDisabledInput(false);
          document.getElementById('manager').getElementsByClassName('MuiButton-label')[0].innerHTML = 'DONE'
        } else {
          form.role=roleValue;
          dispatch(Action.updateOtherUsers(form));
          document.getElementById('manager').getElementsByClassName('MuiButton-label')[0].innerHTML = 'EDIT'
          setDisabledInput(true);
        }
  
      }

      function handleSubmit(event)
        {
        
            // dispatch(Action.createSuperUser(form, props.history))
            // dispatch(Action.setup(props.history.location.form))

        }

        const handleSelectChange = (event) => {
            setRole(event.target.value);
          };
        const formRef = useRef(null);

        useEffect(() => {
          dispatch(Actions.getUser(userId));
      }, [dispatch]);


      if(!user) {
        return null
      }

      if(user) {
        if(reRenderCounter) {
            setForm(user);   
            setRoleValue(user.role)
            reRenderCounter = false;
        } 
    }


    return (
        <>
        <Helmet>
          <title>{`Edit User | ${setup.organizationName}`}</title>
        </Helmet>
        <Box minHeight="1rem" >
          
        </Box>

        <Box paddingTop='55px' margin='20px'>
            <Link to={`/${activeUser.role.toLowerCase()}/manageusers`} style={{color: '#57737A', textDecoration: 'none'}}>
                <Grid container spacing={2}>
                    <NavigateBeforeIcon /> <Typography>Back</Typography>
                </Grid>
            </Link>
        </Box>
        
      
        <Container
        maxWidth={false}
        component={Box}
        marginTop="1rem"
        classes={{ root: classes.containerProv }}
      >

          

<Card style={{
            boxShadow: "0px -2px 4px rgba(234, 234, 234, 0.25), 0px 2px 8px rgba(50, 50, 93, 0.15)", borderRadius: "6px", marginTop: '-35px'
            
          }} >

         <CardHeader  subheader={<Typography color="primary" style={{fontWeight: 'bold'}}>User Details</Typography>} style={{
                  background:'#F7FAFC'
                }}></CardHeader>
            <CardContent>

            <Formsy
                onValidSubmit={handleSubmit}
                ref={formRef}                   
            >      

                    <Grid container>
                    <Grid item xs={12} lg={4} >
                    <FormLabel>First Name</FormLabel>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <FilledInput
                        autoComplete="off"
                        name="firstName"
                        disabled={disabledInput}
                        value={form.firstName}
                        onChange={handleChange}
                        type="text"
                        required                     
                        placeholder="First Name"
                      />
                    </FormControl>
                    
                    </Grid>
                    <Grid item xs={12} lg={4}>
                    <FormLabel>Last Name</FormLabel>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <FilledInput
                        autoComplete="off"
                        name="lastName"
                        disabled={disabledInput}
                        value={form.lastName}
                        type="text"
                        required
                        onChange={handleChange}
                        placeholder="Last Name"
                       
                      />
                    </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                    <FormLabel>Email</FormLabel>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <FilledInput
                        autoComplete="off"
                        name="email"
                        type="email"
                        disabled={disabledInput}
                        value={form.email}
                        onChange={handleChange}
                        required                        
                        placeholder="Email"
                        
                      />
                    </FormControl>

                    </Grid>

                    
                    
                  </Grid>

                  

                    <Grid container>

                    <Grid item xs={12} lg={4} >
                    <FormLabel>Phone Number</FormLabel>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <FilledInput
                        autoComplete="off"
                        name="phoneNumber"
                        disabled={disabledInput}
                        value={form.phoneNumber}
                        onChange={handleChange}
                        type="text"
                        required                     
                        placeholder="First Name"
                      />
                    </FormControl>
                    
                    </Grid>

                    <Grid item xs={12} lg={4} >
                    <FormLabel>Employee ID</FormLabel>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                    >
                      <FilledInput
                        autoComplete="off"
                        name="employeeId"
                        disabled={disabledInput}
                        value={form.employeeId}
                        onChange={handleChange}
                        type="text"
                        required                     
                        placeholder="Employee ID"
                      />
                    </FormControl>
                    
                    </Grid>

                    <Grid item xs={12} lg={4} >
                    <FormLabel>Role</FormLabel>
                    
                    
                    <FormControl
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                        >                       
                        <Select                      
                          
                            
                            IconComponent={(props) => (<ExpandMoreIcon {...props}/>)}
                            input={<BootstrapInput disabled={disabledInput} name="productOemW"
                            name="role"
                            onChange={(e) => setRoleValue(e.target.value)} />}
                            value={roleValue}
                            >
                            
                            <Divider />
                            <MenuItem className={classes2.menuitem} key="1" value="admin" >Admin</MenuItem>
                            <Divider />
                            <MenuItem className={classes2.menuitem} key="2" value="approver" >Approver</MenuItem>
                            <Divider />
                            <MenuItem className={classes2.menuitem} key="3" value="requestor" >requestor</MenuItem>
                            
                        </Select>
                        </FormControl>
                        
                  
        {/* <Select
          value={value}
          defaultValue="none"
          fullWidth
          
          onChange={(e) => setValue(e.target.value)}
          onFocus={(e) => setShowPlaceholder(false)}
          onClose={(e) => setShowPlaceholder(e.target.value === undefined)}
          className={clsx(classes2.select, value === "none" ? classes2.selectdisabled : null)}
        >
                
        </Select> */}
                    

                   
                    
                    </Grid>
                    
                    </Grid>

                    <Box textAlign="center" >
                    <Button onClick={buttonClicked} id="manager" style={{background: '#e30c57', border: '#e30c57', paddingLeft: '40px' ,
                      paddingRight: '40px', borderRadius: '5px',
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)"}} type="submit" variant="contained" >
                        EDIT
                      </Button> 
                              
                  </Box>

                  
                </Formsy>

                    </CardContent>

                     
            </Card>

        </Container>

</>

)

}

export default EditUser;