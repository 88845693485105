import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons components
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import StorageIcon from '@mui/icons-material/Storage';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import ListIcon from '@mui/icons-material/List';

// core components
import CardStats from "views/common/Cards/CardStats.js";

import componentStyles from "assets/theme/components/header.js";

const useStyles = makeStyles(componentStyles);

const FirstHeader = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <div className={classes.header}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <div>
            <Grid container>
            <Grid item xl={4} lg={8} xs={12}>
                <CardStats
                  subtitle="Total request"
                  title={props.requests}
                  icon={ListIcon}
                  color="bgInfo"
                 
                />
              </Grid>
              <Grid item xl={4} lg={8} xs={12}>
                <CardStats
                  subtitle="Pending Request"
                  title={props.pending}
                  icon={PendingActionsIcon}
                  color="bgWarningLight"
                 
                />
              </Grid>
              <Grid item xl={4} lg={8} xs={12}>
                <CardStats
                  subtitle="Executing request"
                  title={props.executing}
                  icon={HourglassBottomIcon}
                  color="bgWarningLight"
                 
                />
              </Grid>
             
              
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default FirstHeader;
